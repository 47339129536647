import React from 'react'
import './App.scss'
import { AppRouter } from "./AppRouter";
import "firebase/analytics";

function App() {

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
