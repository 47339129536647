import './Scene.css'
import React, { useEffect, useState } from 'react'
import { Canvas } from 'react-three-fiber'
import { Controls } from "./controls/Controls";
import { Model } from "./model/Model";
import { PlaybackControls } from "../player/Player";
import { PI } from "./3DHelpers";
import { ResizeObserver } from '@juggle/resize-observer';
import * as THREE from 'three';
import { Watermark } from "../watermark/Watermark";
import { ReviewPanel } from "../review-panel/ReviewPanel";
import { ThreeDeeManipulationHints } from '../three-dee-manipulation-hints/ThreeDeeManipulationHints';
import { CookieConsent } from '../cookie-consent/CookieConsent';
import { Model2 } from './model2/Model2';
import { loadAnimations, TAnimation } from "../loaders/AnimationLoader";
import { DynamicTexture } from './DynamicTexture';

export const SHADOWS = false;
export const DEFAULT_BACKGROUND_COLOR = '#009fe3';

export enum Mode {
  DEFAULT,
  VIEW,
  REVIEW
}

type SceneProps = {
  version?: number
  mode?: Mode
  layoutId: string
  mirrorLayout?: boolean
  textureOrUrl: DynamicTexture | string
  backTextureUrl?: string
  sbsTextureMode?: 'h' | 'v'
  embedded?: boolean
  transparent?: boolean
  color?: string
  projectTitle?: string
  folded?: boolean
  suppressConsent?: boolean
}

const getBackgroundColor = ( color: string ): string => {
  if ( !!color ) {
    if ( color.startsWith('#') ) return color;
    return '#' + color;
  }
  return DEFAULT_BACKGROUND_COLOR;
}

export const Scene = ({ version, mode, layoutId, mirrorLayout, textureOrUrl, backTextureUrl, sbsTextureMode, embedded, transparent, color, projectTitle, folded, suppressConsent }: SceneProps) => {
  const [ threeCamera, setThreeCamera ] = useState<THREE.Camera>(); // little hack to get the main scene camera
  const [ threeClock, setThreeClock ] = useState<THREE.Clock>(); // little hack to get the main scene clock

  const [ animations, setAnimations ] = useState<TAnimation>();
  useEffect(() => loadAnimations(layoutId, mirrorLayout, setAnimations), [ layoutId, mirrorLayout ]);

  useEffect(() => {
    if (folded && animations?.folding && !!threeClock) {
      threeClock.elapsedTime = animations.folding.duration;
    }
  }, [ folded, animations, threeClock ]);

  const modelVersion: number = version ?? (layoutId.startsWith("bx") ? 2 : 1);

  const backgroundColor = getBackgroundColor( color );
  const glClearColor = (transparent === true) ? null : new THREE.Color(backgroundColor);

  const glParams: Partial<THREE.WebGLRendererParameters> = {
    alpha: (transparent === true), // enable transparency only if needed
    antialias: true
  };

  return (<>
    <Canvas
        className="scene-canvas"
        resize={ { polyfill: ResizeObserver } }
        pixelRatio={ window.devicePixelRatio || 1 }
        concurrent
        invalidateFrameloop // disable 60 fps animation
        camera={ { /*position: [ mirrorLayout ? -7.35 : 7.35, 0, 17.6 ],*/ fov: 65, near: 0.1, far: 200 } }
        gl={ glParams }
        colorManagement={ true }
        shadowMap={ SHADOWS ? { enabled: true, type: THREE.PCFSoftShadowMap } : undefined }
        onCreated={ ({ gl, camera, clock }) => {
          gl.setClearColor(glClearColor);
          setThreeCamera(camera);
          clock.stop();
          clock.elapsedTime = 0;
          setThreeClock(clock);
        } }
    >
      <ambientLight
          intensity={ 0.5 }
      />
      <spotLight
          castShadow={ SHADOWS }
          angle={ PI / 8 }
          intensity={ 0.3 }
          position={ [ 10, 10, 40 ] }
          shadow={ SHADOWS ? { mapSize: { width: 2048, height: 2048 } } : undefined }
      />
      <spotLight
          castShadow={ SHADOWS }
          angle={ PI / 8 }
          intensity={ 0.3 }
          position={ [ -10, 10, -40 ] }
          shadow={ SHADOWS ? { mapSize: { width: 2048, height: 2048 } } : undefined }
      />
      <Controls/>
      { (modelVersion === 2) ?
          <Model2
              animation={ animations?.folding }
              modelLayoutId={ layoutId }
              mirrorLayout={ mirrorLayout }
              textureOrFile={ textureOrUrl }
              backTextureFile={ backTextureUrl }
              sbsTextureMode={ sbsTextureMode }
          />
          :
          <Model
              animation={ animations?.folding }
              modelLayoutId={ layoutId }
              mirrorLayout={ mirrorLayout }
              textureOrFile={ textureOrUrl }
          />
      }
    </Canvas>
    { mode === Mode.REVIEW ? <Watermark/> : null }
    {/*<Fps/>*/}
    { mode === Mode.REVIEW ? <ReviewPanel projectTitle={ projectTitle }/> : null }
    <ThreeDeeManipulationHints/>
    <PlaybackControls
        threeCamera={ threeCamera }
        threeClock={ threeClock }
        startPos={ folded ? animations?.folding?.duration : 0 }
        playLength={ animations?.folding?.duration }
        playSpeed={ 1.0 / (animations?.folding?.scale || 1.0) }
        embedded={ embedded }
        hidden={ folded }
    />
    { !!folded ? null : <>
      <CookieConsent
          supress={ suppressConsent || embedded }
      />
    </>}
  </>);
};
