import { getApps, initializeApp } from 'firebase/app';

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDkPg3hL8UM01Gyr1S2pXhIccwFVNFqj1Y",
  authDomain: "howtovouw.firebaseapp.com",
  databaseURL: "https://howtovouw-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "howtovouw",
  storageBucket: "howtovouw.appspot.com",
  messagingSenderId: "753196715130",
  appId: "1:753196715130:web:eba139b726c095db7b7fc3",
  measurementId: "G-TRG7HGYLQR"
};

const getOrInitFirebaseApp = () => {
  if (getApps()?.length) {
    return getApps()[0];
  } else {
    return initializeApp(FIREBASE_CONFIG);
  }
}

const firebaseApp = getOrInitFirebaseApp();

export { firebaseApp };

