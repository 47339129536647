import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFrame } from "react-three-fiber";
import * as THREE from "three";
import { loadLayout, TModelEdgeNode, TModelLayout } from "../../loaders/LayoutLoader";
import { addSupportForUvB, antiVector, interpolateAttributeValuesAcrossSegments, interpolateCamera, interpolateMult, PI, X, Y, Z } from "../3DHelpers";
import { loadEdgeData, loadTexture, TModelEdgeData } from "../../loaders/TextureLoader";
import { TAnimationScenario } from "../../loaders/AnimationLoader";
import { SHADOWS } from '../Scene';
import { Camera } from "react-three-fiber/canvas";
import { DynamicTexture } from "../DynamicTexture";

export const TEMPLATE_SIZE = [ 24.81, 33.64 ];
const SHININESS = 70;
const PAPER_COLOR = 0xEEEEEE;

const SEEK_DELTA_THRESHOLD = 0.0001;

type ExcessProps = {
  modelTexture: THREE.Texture
  modelEdgeData: TModelEdgeData
  getExcessExtent: (elapsed: number) => number
}

// TODO: enabling this makes bright washed-out colors effect
// const createExcessMaterial = (modelTextures: TModelTextureData): THREE.Material => (!modelTextures) ? null : (
//     new THREE.MeshPhongMaterial({
//       side: THREE.DoubleSide,
//       //premultipliedAlpha: true,
//       //color: modelEdge.color,
//       map: modelTextures.texture,
//       alphaMap: modelTextures.excessAlphaMap,
//       alphaTest: 0.5,
//       //shininess: 1,
//       //transparent: true,
//       //opacity: 0.8
//     })
// );

const getPaperColor = ( modelEdgeData: TModelEdgeData ) => {
  if (modelEdgeData.edges['tail-left-ear']) // TODO: workaround for VouwTram
    return 0x111111;
  else
    return PAPER_COLOR;
}

const Excess = ({ modelTexture, modelEdgeData, getExcessExtent }: ExcessProps) => {

  const meshRef = useRef<THREE.Mesh>();
  const meshBackRef = useRef<THREE.Mesh>();
  const materialRef = useRef<THREE.Material>();
  const materialBackRef = useRef<THREE.Material>();

  const depthMaterial = useMemo(() => SHADOWS ? new THREE.MeshDepthMaterial({
    side: THREE.DoubleSide,
    depthPacking: THREE.RGBADepthPacking,
    alphaMap: modelEdgeData.excessAlphaMap,
    alphaTest: 0.5
  }) : null, [ modelEdgeData ]);

  const geometry = useMemo(() => new THREE.PlaneBufferGeometry(...modelEdgeData.templateDims), [ modelEdgeData.templateDims ]);

  // const excessMaterial = useMemo(() => createExcessMaterial(modelTextures), [ modelTextures ]);

  useFrame(({ clock, invalidate }) => {
    if (!meshRef.current || !meshBackRef.current || !materialRef.current) return false;
    const prevExtent = meshRef.current.userData['extent'];
    const extent = getExcessExtent(clock.elapsedTime);
    if (extent === undefined || extent === prevExtent) return false;
    meshRef.current.userData['extent'] = extent;
    if (extent === 1) {
      meshRef.current.visible = false;
      meshBackRef.current.visible = false;
    } else {
      // front side
      meshRef.current.visible = true;
      meshRef.current.position.z = -5 * extent;
      meshRef.current.castShadow = SHADOWS ? (extent < 0.5) : false;
      materialRef.current.opacity = 1 - extent;
      // back side
      meshBackRef.current.visible = true;
      meshBackRef.current.position.z = -5 * extent;
      meshBackRef.current.castShadow = SHADOWS ? (extent < 0.5) : false;
      materialBackRef.current.opacity = 1 - extent;
    }
    invalidate();
    return true;
  });

  return (<>
    <mesh
        ref={ meshRef }
        castShadow={ SHADOWS }
        receiveShadow={ SHADOWS }
        customDepthMaterial={ depthMaterial }
        geometry={ geometry }
        // material={ excessMaterial }
        //position={ [ 0, 0, 0.012 ] } // bump to the front a bit to reduce overlapping
        // ref={ mesh }
        // onClick={ (event) => setActive(!active) }
        // onPointerOver={ (event) => setHover(true) }
        // onPointerOut={ (event) => setHover(false) }
    >
      <meshPhongMaterial // meshBasicMaterial //
          ref={ materialRef }
          side={ THREE.FrontSide }
          map={ modelTexture }
          alphaMap={ modelEdgeData.excessAlphaMap }
          alphaTest={ 0.3 }
          shininess={ SHININESS }
          transparent={ true }
          //opacity={ 0.8 }
      />
    </mesh>
    <mesh
        ref={ meshBackRef }
        castShadow={ SHADOWS }
        receiveShadow={ SHADOWS }
        customDepthMaterial={ depthMaterial }
        geometry={ geometry }
        // material={ excessMaterial }
        // position={ [ 0, 0, -3 ] }
        // ref={ mesh }
        // onClick={ (event) => setActive(!active) }
        // onPointerOver={ (event) => setHover(true) }
        // onPointerOut={ (event) => setHover(false) }
    >
      <meshPhongMaterial // meshBasicMaterial //
          ref={ materialBackRef }
          side={ THREE.BackSide }
          color={ getPaperColor( modelEdgeData ) }
          alphaMap={ modelEdgeData.excessAlphaMap }
          alphaTest={ 0.3 }
          //shininess={ SHININESS }
          transparent={ true }
          //opacity={ 0.8 }
      />
    </mesh>
  </>);
};

type ModelEdgeProps = {
  modelEdge: TModelEdgeNode
  modelTexture: THREE.Texture
  modelEdgeData: TModelEdgeData
  edgeMaterial: THREE.Material
  edgeBackMaterial: THREE.Material
  edgeDepthMaterial: THREE.MeshDepthMaterial
  getEdgeExtent: (edgeId: string, elapsed: number) => number
}

const createEdgeGeometry = (modelEdgeData: TModelEdgeData, edge: TModelEdgeNode): THREE.BufferGeometry => {
  const { boundingBox: bb, alphaBox: ab } = modelEdgeData.edges[edge.id];
  const [ w, h ] = modelEdgeData.templateDims;
  const bendable = !!edge.bendExtent;
  const segments = bendable ? [ 12, 1 ] : [ 1, 1 ];

  // Positions
  const x0 = (bb[0][X] - 0.5) * w;
  const y0 = (bb[0][Y] - 0.5) * h;
  const x1 = (bb[1][X] - 0.5) * w;
  const y1 = (bb[1][Y] - 0.5) * h;

  // note it's Y-flipped!
  const position = interpolateAttributeValuesAcrossSegments(
      [
        [ x0, y0, 0 ],
        [ x1, y0, 0 ]
      ], [
        [ x0, y1, 0 ],
        [ x1, y1, 0 ]
      ], segments[X]
  );
  //console.log("position", JSON.stringify(position));

  // Alpha UVs
  // note it's Y-flipped!
  const uvA = interpolateAttributeValuesAcrossSegments(
      [
        [ ab[0][X], 1 - ab[0][Y] ],
        [ ab[1][X], 1 - ab[0][Y] ]
      ], [
        [ ab[0][X], 1 - ab[1][Y] ],
        [ ab[1][X], 1 - ab[1][Y] ]
      ], segments[X]
  );

  // Texture UVs
  // note it's Y-flipped!
  const uvB = interpolateAttributeValuesAcrossSegments(
      [
        [ bb[0][X], 1 - bb[0][Y] ],
        [ bb[1][X], 1 - bb[0][Y] ]
      ], [
        [ bb[0][X], 1 - bb[1][Y] ],
        [ bb[1][X], 1 - bb[1][Y] ]
      ], segments[X]
  );

  // // Normals
  // const normals = interpolateAttributeValuesAcrossSegments(
  //     [
  //       [ 0, 0, 1 ],
  //       [ 0, 0, 1 ]
  //     ], [
  //       [ 0, 0, 1 ],
  //       [ 0, 0, 1 ]
  //     ], segments[X]
  // );

  const geo = new THREE.PlaneGeometry(w, h, ...segments);
  geo.setAttribute('position', new THREE.BufferAttribute(position, 3));
  geo.setAttribute('uv', new THREE.BufferAttribute(uvA, 2));
  geo.setAttribute('uvB', new THREE.BufferAttribute(uvB, 2));
  // geo.setAttribute('normals', new THREE.BufferAttribute(normals, 3, true));
  geo.userData['boundingBox'] = [ [ x0, y0 ], [ x1, y1 ] ];

  return geo;
};

const bendGeometry = (geometry: THREE.BufferGeometry, bendExtent: number, foldAngle: number): void => {
  const positions: THREE.BufferAttribute = geometry.getAttribute('position');
  const columns = positions.count / 2; // 2 rows per column
  const bb = geometry.userData['boundingBox'];
  const squeezeExtent = Math.sin(foldAngle) * 0.1;
  for (let i = 1; i < columns - 1; i++) { // first and last column should not change
    const zValue = -bendExtent * Math.sin(i * PI / (columns - 1));
    // top row
    positions.setZ(i, zValue);
    positions.setX(i, bb[0][X] + squeezeExtent);
    // bottom row
    positions.setZ(i + columns, zValue);
    positions.setX(i + columns, bb[1][X] - squeezeExtent);
  }
  positions.needsUpdate = true;
};

const ModelEdge = ({ modelEdge, modelTexture, modelEdgeData, edgeMaterial, edgeBackMaterial, edgeDepthMaterial, getEdgeExtent }: ModelEdgeProps) => {
  // console.log('Rendering ModelEdge');

  const folderRef = useRef<THREE.Group>();

  const rotation = modelEdge.foldAxisRotation;
  const templateDims = modelEdgeData.templateDims;
  const position = [ modelEdge.offset[X] * templateDims[X], -modelEdge.offset[Y] * templateDims[Y], modelEdge.offset[Z] ];

  const geometry = useMemo(() => modelEdge.invisible ? null : createEdgeGeometry(modelEdgeData, modelEdge), [ modelEdgeData, modelEdge ]);

  const animateEdge = (extent: number) => {
    if (modelEdge.foldAngle) {
      const foldAngle = modelEdge.foldAngle * extent;
      folderRef.current.rotation.x = -foldAngle;
      if (modelEdge.bendExtent) {
        const bendExtent = modelEdge.bendExtent * extent;
        bendGeometry(geometry, bendExtent, foldAngle);
      }
    }
  };

  useFrame(({ clock, invalidate }) => {
    if ((!modelEdge.foldAngle && !modelEdge.bendExtent) || !folderRef.current) return false;
    const prevExtent = folderRef.current.userData['extent'];
    const extent = getEdgeExtent(modelEdge.id, clock.elapsedTime);
    if (extent === prevExtent) return false;
    animateEdge(extent);
    folderRef.current.userData['extent'] = extent;
    invalidate();
    return true;
  });

  const currentFrame = folderRef?.current?.userData['frame'];
  if (currentFrame) animateEdge((1 - Math.cos(currentFrame)) / 2);

  const mesh = modelEdge.invisible ? null : (<>
    <mesh
        castShadow={ SHADOWS }
        receiveShadow={ SHADOWS }
        customDepthMaterial={ edgeDepthMaterial }
        geometry={ geometry }
        material={ edgeMaterial }
        position={ [ 0, 0, 0.00001 ] } // bump to the front a bit to reduce overlapping
        scale={ [ 1, -1, 1 ] } // flip-Y to fix inside-out effect
        // ref={ mesh }
        // onClick={ (event) => setActive(!active) }
        // onPointerOver={ (event) => setHover(true) }
        // onPointerOut={ (event) => setHover(false) }
    />
    <mesh
        castShadow={ SHADOWS }
        receiveShadow={ SHADOWS }
        customDepthMaterial={ edgeDepthMaterial }
        geometry={ geometry }
        material={ edgeBackMaterial }
        position={ [ 0, 0, -0.00001 ] } // bump to the back a bit to reduce overlapping
        scale={ [ 1, -1, 1 ] } // flip-Y to fix inside-out effect
        // ref={ mesh }
        // onClick={ (event) => setActive(!active) }
        // onPointerOver={ (event) => setHover(true) }
        // onPointerOut={ (event) => setHover(false) }
    />
  </>);

  const connectedEdges = (
      <ModelEdges
          modelEdgeTree={ modelEdge.connectedEdges }
          modelTexture={ modelTexture }
          modelEdgeData={ modelEdgeData }
          edgeMaterial={ edgeMaterial }
          edgeBackMaterial={ edgeBackMaterial }
          edgeDepthMaterial={ edgeDepthMaterial }
          getEdgeExtent={ getEdgeExtent }
      />
  );

  const useRotation: boolean = rotation[Z] !== 0; // we only rotate around Z axis

  return useRotation ? (
      <group position={ position } rotation={ rotation }>
        <group ref={ folderRef } rotation={ antiVector(rotation) }>
          <group position={ antiVector(position) }>
            { mesh }
            { connectedEdges }
          </group>
        </group>
      </group>
  ) : (
      <group ref={ folderRef } position={ position }>
        <group position={ antiVector(position) }>
          { mesh }
          { connectedEdges }
        </group>
      </group>
  );
};

type ModelEdgesProps = {
  modelEdgeTree: Array<TModelEdgeNode>
  modelTexture: THREE.Texture
  modelEdgeData: TModelEdgeData
  edgeMaterial: THREE.Material
  edgeBackMaterial: THREE.Material
  edgeDepthMaterial: THREE.MeshDepthMaterial
  getEdgeExtent: (edgeId: string, elapsed: number) => number
}

const ModelEdges = ({ modelEdgeTree, modelTexture, modelEdgeData, edgeMaterial, edgeBackMaterial, edgeDepthMaterial, getEdgeExtent }: ModelEdgesProps) =>
    <>{ modelEdgeTree.map((modelEdge: TModelEdgeNode) =>
        <ModelEdge
            key={ modelEdge.id }
            modelEdge={ modelEdge }
            modelTexture={ modelTexture }
            modelEdgeData={ modelEdgeData }
            edgeMaterial={ edgeMaterial }
            edgeBackMaterial={ edgeBackMaterial }
            edgeDepthMaterial={ edgeDepthMaterial }
            getEdgeExtent={ getEdgeExtent }
        />
    ) }</>;

const createEdgeMaterial = (modelTexture: THREE.Texture, modelEdgeData: TModelEdgeData): THREE.Material => (!modelTexture || !modelEdgeData) ? null : (
    new THREE.MeshPhongMaterial({
      side: THREE.FrontSide,
      onBeforeCompile: addSupportForUvB,
      //depthPacking: THREE.RGBADepthPacking,
      //premultipliedAlpha: true,
      //color: modelEdge.color,
      map: modelTexture,
      alphaMap: modelEdgeData.edgeAlphaMap,
      alphaTest: 0.5,

      // color:     0x999999,
      // specular:  0x111111,
      shininess: SHININESS,
      //transparent: true,
      //opacity: 0.8
    })
);

const createEdgeBackMaterial = (modelEdgeData: TModelEdgeData): THREE.Material => (!modelEdgeData) ? null : (
    new THREE.MeshPhongMaterial({
      side: THREE.BackSide,
      //onBeforeCompile: addSupportForUvB,
      //depthPacking: THREE.RGBADepthPacking,
      //premultipliedAlpha: true,
      color: getPaperColor( modelEdgeData ),
      alphaMap: modelEdgeData.edgeAlphaMap,
      alphaTest: 0.5,

      // color:     0x999999,
      // specular:  0x111111,
      // shininess: SHININESS,
      //transparent: true,
      //opacity: 0.8
    })
);

type ModelProps = {
  modelLayoutId: string
  mirrorLayout?: boolean
  animation: TAnimationScenario
  textureOrFile: DynamicTexture | string
}

const createEdgeDepthMaterial = (modelEdgeData: TModelEdgeData) => (!modelEdgeData) ? null : (
    new THREE.MeshDepthMaterial({
      depthPacking: THREE.RGBADepthPacking,
      alphaMap: modelEdgeData.edgeAlphaMap,
      alphaTest: 0.5
    })
);

const cameraNeedsInit = (camera: Camera): boolean => {
  if (!!camera.userData['initialized']) {
    return false;
  }
  camera.userData['initialized'] = true;
  return true;
}

export const Model = ({ modelLayoutId, mirrorLayout, animation, textureOrFile }: ModelProps) => {
  const [ layout, setLayout ] = useState<TModelLayout>();
  const [ modelEdgeTree, setModelEdgeTree ] = useState<Array<TModelEdgeNode>>();
  useEffect(() => loadLayout(modelLayoutId, setLayout, setModelEdgeTree), [ modelLayoutId ]);
  const [ modelEdgeData, setModelEdgeData ] = useState<TModelEdgeData>();
  useEffect(() => !!layout && loadEdgeData(modelLayoutId, layout, setModelEdgeData), [ modelLayoutId, layout ]);
  const [ modelTexture, setModelTexture ] = useState<THREE.Texture>();
  useEffect(() => loadTexture(textureOrFile, mirrorLayout, setModelTexture), [ textureOrFile, mirrorLayout ]);

  const edgeMaterial = useMemo<THREE.Material>(() => createEdgeMaterial(modelTexture, modelEdgeData), [ modelTexture, modelEdgeData ]);
  const edgeBackMaterial = useMemo<THREE.Material>(() => createEdgeBackMaterial(modelEdgeData), [ modelEdgeData ]);
  const edgeDepthMaterial = useMemo<THREE.Material>(() => SHADOWS ? createEdgeDepthMaterial(modelEdgeData) : null, [ modelEdgeData ]);

  const getExcessExtent = useCallback((elapsed) => interpolateMult(animation?.excess, elapsed) ?? 1.0, [ animation ]);
  const getEdgeExtent = useCallback((edgeId, elapsed) => interpolateMult(animation?.edges[edgeId] ?? animation?.edges['*'], elapsed), [ animation ]);

  useFrame(({ clock, camera, invalidate }) => {
    if (!camera?.userData['seeking']) return false;
    const seekTime = camera.userData['seekTime'];
    if (clock.elapsedTime === seekTime) return false;
    if (Math.abs(clock.elapsedTime - seekTime) < SEEK_DELTA_THRESHOLD) {
      clock.elapsedTime = seekTime;
    } else {
      clock.elapsedTime = (seekTime * 0.1) + (clock.elapsedTime * 0.9);
    }
    invalidate();
    return true;
  });

  useFrame(({ clock, camera, invalidate }) => {
    if (!camera || !!camera.userData['under-control'] || !animation?.camera) return false;
    const needsInit = cameraNeedsInit(camera);
    if (!clock?.running && !camera.userData['seeking'] && !needsInit) return false;
    const elapsed = clock.elapsedTime;
    const newPos = interpolateCamera(animation.camera, camera.position, elapsed);
    if (camera.position.equals(newPos)) return false;
    camera.position.copy(newPos);
    camera.lookAt(0, 0, 0);
    invalidate();
    return true;
  });

  if (!modelEdgeData || !modelTexture) return null; // TODO: a progress bar?

  return (
      <group scale={ [ mirrorLayout ? -0.5 : 0.5, 0.5, 0.5 ] }>
        <Excess
            modelTexture={ modelTexture }
            modelEdgeData={ modelEdgeData }
            getExcessExtent={ getExcessExtent }
        />
        <ModelEdges
            modelEdgeTree={ modelEdgeTree }
            modelTexture={ modelTexture }
            modelEdgeData={ modelEdgeData }
            edgeMaterial={ edgeMaterial }
            edgeBackMaterial={ edgeBackMaterial }
            edgeDepthMaterial={ edgeDepthMaterial }
            getEdgeExtent={ getEdgeExtent }
        />
      </group>
  );
};
