import './Player.scss'
import { useCallback, useEffect, useState } from 'react'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import { Grid, Slider } from "@material-ui/core";
import { addEffect, invalidate } from "react-three-fiber";
import * as THREE from 'three';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const white = '#efefef';

export const MUI_THEME = createMuiTheme({
  palette: {
    primary: {
      main: white
    },
    secondary: grey
  }
});

type PlaybackControlsProps = {
  threeClock: THREE.Clock
  threeCamera: THREE.Camera
  startPos?: number
  playLength: number
  playSpeed: number
  embedded: boolean
  hidden: boolean
}

const DELTA_THRESHOLD = 0.01; // sec

                 
                                                                 
                                                                                            
 
          

export const REWIND_AND_PLAY_EVENT = 'player.rewind-and-play';

export const PlaybackControls = ({ threeClock, threeCamera, startPos, playLength, playSpeed, embedded, hidden = false }: PlaybackControlsProps) => {
  const [ pos, setPos ] = useState<number>( startPos ?? 0 );
  const [ playerPaused, setPlayerPaused ] = useState(true);
  const [ coverPlayButton, setCoverPlaybutton ] = useState(embedded);

  const onPlayPause = (isPaused) => {
    if (threeClock) {
      if (isPaused) {
        threeClock.stop();
                 
                                       
          
      } else {
        const elapsed = threeClock.elapsedTime;
        threeClock.start(); // this re-sets the elapsed time..
        if (elapsed < playLength) { // if not at the end
          threeClock.elapsedTime = elapsed;
        }
        invalidate();
      }
    }
    setPlayerPaused(isPaused);
  };

  const playAndSeek = useCallback((pause = false, seekPos = 0) => {
    if (pause) {
      threeClock.stop();
    } else {
      threeClock.start();
    }
    threeClock.elapsedTime = seekPos;
    setPlayerPaused(pause);
    invalidate();
  }, [ threeClock ]);

  useEffect(() => {
    const pandrListener = () => {
      playAndSeek(false, 0);
    }
    window.addEventListener(REWIND_AND_PLAY_EVENT, pandrListener);
    return () => window.removeEventListener(REWIND_AND_PLAY_EVENT, pandrListener);
  }, [ threeClock, playAndSeek ]);


  const handleChange = (event: any, newPos: number) => {
    if (!playerPaused) {
      threeClock.stop();
      setPlayerPaused(true);
    }
    if (newPos === pos) return;
    setPos(newPos);
    threeCamera.userData['seekTime'] = newPos;
    threeCamera.userData['seeking'] = true;
    invalidate();
  };

  const handleChangeEnd = () => {
    threeCamera.userData['seeking'] = false;
                 
                                   
          
  };

  addEffect(() => {
    if (!threeClock || playerPaused) return false;
    const delta = threeClock.getDelta();
    if (delta > DELTA_THRESHOLD) {
      const newPos = threeClock.elapsedTime;
      if (newPos < playLength) {
        setPos(newPos);
      } else { // animation finished
        threeClock.stop();
        threeClock.elapsedTime = playLength;
        setPlayerPaused(true);
        invalidate();
        setPos(playLength);
      }
    }
    return true;
  });

  const onClickCoverPlayButton = () => {
    onPlayPause(false); // start the playback
    setCoverPlaybutton(false); // hide the cover play button
  };

  return (coverPlayButton ? <CoverPlayButton onClick={ onClickCoverPlayButton }/> :
          <MuiThemeProvider theme={ MUI_THEME }>
            <div className="playback-controls" style={{ display: hidden ? 'none' : 'block' }}>
              <Grid container alignItems="center">
                <Grid item onClick={ () => onPlayPause(!playerPaused) }>
                  <div className="playback-controls-button">
                    { playerPaused
                        ? <PlayArrowRoundedIcon htmlColor="#efefef" fontSize="large"/>
                        : <PauseRoundedIcon htmlColor="#efefef" fontSize="large"/>
                    }
                  </div>
                </Grid>
                <Grid item xs>
                  <div className="playback-controls-slider">
                    <Slider
                        value={ pos }
                        max={ playLength }
                        step={ 0.0001 }
                        //valueLabelDisplay="auto"
                        onChange={ handleChange }
                        onChangeCommitted={ handleChangeEnd }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </MuiThemeProvider>
  );
};

const CoverPlayButton = ({ onClick }) => (
    //<div className="cover-play-button-panel">
      <div className="cover-play-button-circle" onClick={ onClick }>
        <MuiThemeProvider theme={ MUI_THEME }>
          <div className="cover-play-button">
            <PlayArrowRoundedIcon htmlColor="#efefef" fontSize="inherit"/>
          </div>
        </MuiThemeProvider>
      </div>
    //</div>
);
