import React from 'react';
import './index.css';
import App from './components/app/App';
import { createRoot } from "react-dom/client";

if (!console.timeLog) console.timeLog = () => {};
if (!console.time) console.time = () => {};
if (!console.timeEnd) console.timeEnd = () => {};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render( <App /> );
