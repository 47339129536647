import { Vector2, Vector3 } from "react-three-fiber/three-types"
import * as THREE from 'three'
import { TAnimationCamera, TAnimationProps } from "../loaders/AnimationLoader";
import { easing } from 'ts-easing';

export const X = 0, Y = 1, Z = 2;
export const PI = Math.PI;

const EASE_CAMERA = easing.inOutQuad;
const EASE_EXTENT = easing.inOutCubic;

// export const mul2 = (v1: Vector2, v2: Vector2): Vector2 => (!!v1) && (!!v2) ? [ v1[X] * v2[X], v1[Y] * v2[Y] ] : undefined;
export const sub2 = (v1: Vector2, v2: Vector2): Vector2 => (!!v1) && (!!v2) ? [ v1[X] - v2[X], v1[Y] - v2[Y] ] : undefined;
export const add2 = (v1: Vector2, v2: Vector2): Vector2 => (!!v1) && (!!v2) ? [ v1[X] + v2[X], v1[Y] + v2[Y] ] : undefined;
// export const mul2_5 = (v1: Vector3, v2: Vector2): Vector3 => (!!v1) && (!!v2) ? [ v1[X] * v2[X], v1[Y] * v2[Y], v1[Z] ] : undefined;
export const antiVector = (v: Vector3): Vector3 => v ? [ -v[X], -v[Y], -v[Z] ] : v;

export const interpolateAttributeValuesAcrossSegments = (from: Array<Array<number>>, to: Array<Array<number>>, count: number): Float32Array => {
  const [ w, h, d ] = [ count + 1, 2, from[0].length ];
  const attributeValueArray: Float32Array = new Float32Array(w * h * d);
  let idx = 0;
  for (let y = 0; y < h; y++) {
    for (let x = 0; x < w; x++) {
      for (let i = 0; i < d; i++, idx++) {
        const interpolatedValue = (x === 0) ? from[y][i] : (x === count) ? to[y][i] : from[y][i] + ((to[y][i] - from[y][i]) * x / count);
        attributeValueArray[idx] = interpolatedValue;
      }
    }
  }
  return attributeValueArray;
};


// adopted from this source: https://stackoverflow.com/questions/59972629/threejs-texturemap-uv-with-independent-alphamap
export const addSupportForUvB = ( shader: THREE.Shader ) => {

  // vertex modifications
  const vertex_pars = 'attribute vec2 uvB;\nvarying vec2 vUvB;\n';
  const vertex_uv = shader.vertexShader.replace(
      '#include <uv_vertex>',
      [
        '#include <uv_vertex>',
        'vUvB = uvB;'
      ].join( '\n' )
  );

  shader.vertexShader = vertex_pars + vertex_uv;


  // fragment modifications
  const frag_pars = 'varying vec2 vUvB;\n';
  const frag_uv = shader.fragmentShader.replace(
      '#include <map_fragment>',
      [
        'vec4 texelColor = texture2D( map, vUvB );',
        'texelColor = mapTexelToLinear( texelColor );',
        'diffuseColor *= texelColor;'
      ].join( '\n' )
  );

  shader.fragmentShader = frag_pars + frag_uv;

};

const CAMERA_PREV = new THREE.Vector3();
const CAMERA_NEXT = new THREE.Vector3();

export const interpolateCamera = (camAnimProps: TAnimationCamera, pos: THREE.Vector3, time: number): THREE.Vector3 => {
  if (camAnimProps === undefined) return pos;
  const t = camAnimProps.time;

  let idx = 0;
  while (idx < t.length && time > t[idx]) idx++;
  const idxNext = (idx === t.length) ? idx - 1 : idx;
  const idxPrev = (idx === 0) ? 0 : idx - 1;

  const capx = camAnimProps['x'];
  const capy = camAnimProps['y'];
  const capz = camAnimProps['z'];

  const prev = CAMERA_PREV.set( capx[idxPrev], capy[idxPrev], capz[idxPrev] );
  if (idxNext === idxPrev) return prev;

  const next = CAMERA_NEXT.set( capx[idxNext], capy[idxNext], capz[idxNext] );

  const interTime = EASE_CAMERA((time - t[idxPrev]) / (t[idxNext] - t[idxPrev]));

  return prev.lerp( next, interTime );
};

const POS_VECT = new THREE.Vector3();

export const interpolatePos = (v1: THREE.Vector3, v2: Array<number>, extent: number): THREE.Vector3 => {
  const extentFrom = 1 - extent;
  return POS_VECT.set(
    (v1[X] * extentFrom) + (v2[X] * extent),
    (v1[Y] * extentFrom) + (v2[Y] * extent),
    (v1[Z] * extentFrom) + (v2[Z] * extent)
  );
}

export const interpolateMult = (edgeAnimProps: TAnimationProps, time: number): number => {
  if (edgeAnimProps === undefined) return undefined;
  const t = edgeAnimProps.time;
  const m = edgeAnimProps.mult;
  const lastIdx = t.length - 1;
  if (time <= t[0]) return m[0];
  if (time >= t[lastIdx]) return m[lastIdx];

  let idx = 0;
  while (idx < t.length && time > t[idx]) idx++;

  const interValue = m[idx] - m[idx - 1];
  const addedValue = m[idx - 1];
  if (interValue === 0) return addedValue;

  const extent = (time - t[idx - 1]) / (t[idx] - t[idx - 1]);
  return EASE_EXTENT(extent) * interValue + addedValue;
};
