import * as THREE from "three";
import { sub2, X, Y } from "../scene/3DHelpers";
import { TEMPLATE_SIZE } from "../scene/model/Model";
import { TModelLayout } from "./LayoutLoader";
import { PdfLoader } from "./PdfLoader";
import { DynamicTexture } from "../scene/DynamicTexture";


const ceilPowerOfTwo = THREE.MathUtils.ceilPowerOfTwo; // smallest power of two, that fits the number

const CHANNELS = 4;
const ALPHA_THRESHOLD = 128;
const ALPHA_EDGE_PADDING = 3;
const A_FEW_PIXELS = 2;
const EXCESS_CTX_LINE_WIDTH = 2;
const CTX_LINE_WIDTH = 2;


type TModelEdgeTextureBoxes = {
  boundingBox: Array<Array<number>>
  alphaBox?: Array<Array<number>>
}

export type TModelEdgeTextureData = {
  [id: string]: TModelEdgeTextureBoxes
}

export type TModelEdgeData = {
  templateDims: Array<number>
  excessAlphaMap: THREE.Texture
  edgeAlphaMap: THREE.Texture
  edges: TModelEdgeTextureData
}


const mirrored = (textureImage) => {
  const imgCanvas = document.createElement('canvas');
  imgCanvas.width = textureImage.width;
  imgCanvas.height = textureImage.height;
  const imgCanvasCtx = imgCanvas.getContext('2d');

  imgCanvasCtx.translate(textureImage.width, 0);
  imgCanvasCtx.scale(-1, 1);

  imgCanvasCtx.drawImage(textureImage, 0, 0);

  return imgCanvas;
}


export const loadTexture = (textureOrFile: DynamicTexture | string, mirrorLayout: boolean, onTextureLoaded: (texture: THREE.Texture) => void) => {
  //console.time("loadTexture");
  if (!textureOrFile) return;

  if (textureOrFile instanceof DynamicTexture) {
    onTextureLoaded(textureOrFile.getTexture());
    return;
  }

  const textureFile = textureOrFile as string;
  const loader : THREE.Loader = textureFile.endsWith('.pdf')
      ? new PdfLoader()
      : new THREE.ImageLoader().setCrossOrigin('*');

  const loadCallback = textureImage => {
    //console.timeLog("loadTexture", "ImageLoader.onLoad");

    const textureSource = mirrorLayout ? mirrored(textureImage) : textureImage;

    const texture: THREE.Texture = new THREE.Texture(textureSource);
    // texture.generateMipmaps = false;
    // texture.wrapS = THREE.ClampToEdgeWrapping;
    // texture.wrapT = THREE.ClampToEdgeWrapping;

    //texture.minFilter = THREE.LinearMipMapLinearFilter;

    texture.magFilter = THREE.LinearFilter;
    texture.minFilter = THREE.LinearMipMapLinearFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    texture.anisotropy = 16;

    texture.needsUpdate = true;

    // return result via the callback
    onTextureLoaded(texture);

    //console.timeEnd("loadTexture");
  }

  loader.load(textureFile, loadCallback, null, console.error);
};


export type Segment = {
  line: number,
  areaId: number
  from: number,
  to: number
}

type AdjacentAreas = {
  [id: number]: Array<number>
}

export type TLayoutAreas = {
  segmentsByLine : Array<Array<Segment>>
  segmentsByAreaId : Array<Array<Segment>>
  adjacentAreas : AdjacentAreas
}

export const calculateLayoutAreas = (image: HTMLImageElement) : TLayoutAreas => {
  const { width, height } = image;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  // if ( flipY === true ) {
  //   ctx.translate( 0, canvas.height );
  //   ctx.scale( 1, - 1 );
  // }

  ctx.drawImage(image, 0, 0, width, height);

  const data: Uint8ClampedArray = ctx.getImageData(0, 0, width, height).data;

  //console.timeLog("loadEdgeData", "Context.getImageData");

  const NO_AREA_ID = 0;
  const adjacentAreas: AdjacentAreas = {}; // = new Array<Array<boolean>>();
  let lastAdjucentAreaId: number = NO_AREA_ID;
  const segmentsByLine = new Array<Array<Segment>>(height);
  const segmentsByAreaId = new Array<Array<Segment>>();
  let segment: Segment;
  let lineSegments: Array<Segment>;

  let prevLineAreaIds: Array<number>;
  let lineAreaIds: Array<number> = [];
  let areaId: number;
  let nextAreaId = 1;
  let wasInside: boolean;

  const alphaOffset = 3; // the alpha channel index in RGBA
  const bytesPerLine = width * CHANNELS;
  let nextLineIndex = 0;
  let line = -1;
  for (let i = alphaOffset, j = 0; i < data.length; i += CHANNELS, j++) {
    if (i >= nextLineIndex) { // line switch
      nextLineIndex += bytesPerLine;
      line++;
      j = 0;
      prevLineAreaIds = lineAreaIds;
      lineAreaIds = new Array(width);
      lineSegments = [];
      segmentsByLine[line] = lineSegments;
      // set outside
      segment = undefined;
      areaId = NO_AREA_ID;
      lastAdjucentAreaId = NO_AREA_ID;
      wasInside = false;
    }

    const nowInside: boolean = (data[i] <= ALPHA_THRESHOLD);

    if (!wasInside && nowInside) { // to inside
      areaId = prevLineAreaIds[j] || nextAreaId++;
      segment = { line, areaId, from: j, to: j };
      lineSegments.push(segment);
      while (segmentsByAreaId.length < nextAreaId) segmentsByAreaId.push([]);
      segmentsByAreaId[areaId].push(segment);
      wasInside = true;
    }

    if (wasInside && nowInside) { // stay inside
      if (segment) segment.to = j;
      const adjucentAreaId = prevLineAreaIds[j];
      if (adjucentAreaId && adjucentAreaId !== lastAdjucentAreaId && adjucentAreaId !== areaId) {
        if (!adjacentAreas[adjucentAreaId]) adjacentAreas[adjucentAreaId] = [];
        if (!adjacentAreas[areaId]) adjacentAreas[areaId] = [];
        adjacentAreas[adjucentAreaId].push(areaId);
        adjacentAreas[areaId].push(adjucentAreaId);
        lastAdjucentAreaId = adjucentAreaId;
      }
    }

    if (wasInside && !nowInside) { // to outside
      segment = undefined;
      areaId = NO_AREA_ID;
      lastAdjucentAreaId = NO_AREA_ID;
      wasInside = false;
    }

    lineAreaIds[j] = areaId;
  }

  return { segmentsByLine, segmentsByAreaId, adjacentAreas };
};


export const loadEdgeData = (layoutId: string, layout: TModelLayout, onEdgeDataLoaded: (modelEdgeData: TModelEdgeData) => void) => {
  //console.time("loadEdgeData");

  const imageLoader = new THREE.ImageLoader()
      .setCrossOrigin('*');

  imageLoader
      .loadAsync(`/templates/${ layoutId }/layout.png`)
      .then(layoutImage => {
    //console.timeLog("loadEdgeData", "ImageLoader.onLoad");

    const { segmentsByLine, segmentsByAreaId, adjacentAreas } = calculateLayoutAreas(layoutImage);

    //console.timeLog("loadEdgeData", "after calculating adjacentAreas");
    //console.log(adjacentAreas);
    const { width, height } = layoutImage;

    const widthRatio = width / layout.dims[X];
    const heightRatio = height / layout.dims[Y];

    type EdgeAlphaDatum = {
      id: string
      origin: Array<number>
      dims: Array<number>
      clusterAreas: Array<number>
    };

    const edgeAlphaData: Array<EdgeAlphaDatum> = [];

    const modelEdgeTextureData: TModelEdgeTextureData = {};

    layout.modelEdges.forEach(modelEdge => {
      if (modelEdge.invisible) return; // skip invisible edges

      const startCol = Math.floor(modelEdge.pointWithin[X] * widthRatio);
      const startLine = Math.floor(modelEdge.pointWithin[Y] * heightRatio);
      const lineSegments = segmentsByLine[startLine];
      const newAreas = [];
      for (let segment of lineSegments) {
        if ((segment.from <= startCol) && (startCol <= segment.to)) {
          newAreas.push(segment.areaId);
          break;
        }
      }
      const clusterAreas = [];
      while (newAreas.length > 0) {
        const areaId = newAreas.pop();
        if (clusterAreas.includes(areaId)) continue;
        clusterAreas.push(areaId);
        const newAdjucentAreas = adjacentAreas[areaId];
        if (newAdjucentAreas) newAreas.push(...newAdjucentAreas);
      }

      const bb = [ [ width, height ], [ -1, -1 ] ];
      for (let areaId of clusterAreas) {
        for (let segment of segmentsByAreaId[areaId]) {
          if (segment.from < bb[0][X]) bb[0][X] = segment.from;
          if (segment.to > bb[1][X])   bb[1][X] = segment.to;
          if (segment.line < bb[0][Y]) bb[0][Y] = segment.line;
          if (segment.line > bb[1][Y]) bb[1][Y] = segment.line;
        }
      }
      // extend the bounding box coverage by a few pixels
      bb[0][X] -= A_FEW_PIXELS;
      bb[0][Y] -= A_FEW_PIXELS;
      bb[1][X] += A_FEW_PIXELS;
      bb[1][Y] += A_FEW_PIXELS;
      if (bb[0][X] < 0) bb[0][X] = 0;
      if (bb[0][Y] < 0) bb[0][Y] = 0;
      if (bb[1][X] >= width) bb[1][X] = width - 1;
      if (bb[1][Y] >= height) bb[1][Y] = height - 1;
      // normalized bounding box
      const boundingBox = [
        [ (bb[0][X] - 1) / width, (bb[0][Y] - 1) / height ],
        [ (bb[1][X] + 1) / width, (bb[1][Y] + 1) / height ]
      ];

      edgeAlphaData.push({
        id: modelEdge.id,
        origin: bb[0],
        dims: sub2(bb[1], bb[0]),
        clusterAreas
      });

      modelEdgeTextureData[modelEdge.id] = { boundingBox };
    });

    // Canvas for excess alphaMap
    const excessCanvas = document.createElement('canvas');
    excessCanvas.width = width;
    excessCanvas.height = height;

    const excessCtx = excessCanvas.getContext('2d');
    excessCtx.fillStyle = 'white';
    excessCtx.fillRect(0, 0, excessCanvas.width, excessCanvas.height);

    excessCtx.lineWidth = EXCESS_CTX_LINE_WIDTH; // take a few pixels from around to capture the folding/cutting lines
    excessCtx.lineCap = 'round';
    excessCtx.strokeStyle = 'black';

    // Compile edge alpha map
    let heightSum = 0;
    let maxEdgeWidth = 0;
    edgeAlphaData.forEach((edge) => {
      if (edge.dims[X] > maxEdgeWidth) maxEdgeWidth = edge.dims[X];
      heightSum += edge.dims[Y];
    });

    const edgesByArea = edgeAlphaData.sort((a, b) => (b.dims[X] * b.dims[Y]) - (a.dims[X] * a.dims[Y]));
    const edgeAlphaMapWidth = 2048; //ceil(maxEdgeWidth * 2);
    const edgeAlphaMapHeight = Math.max(2048, ceilPowerOfTwo(heightSum / 4));
    //const shiftBits = [ Math.clz32(edgeAlphaMapWidth), Math.clz32(edgeAlphaMapHeight) ];

    // Canvas for edge alphaMaps
    const canvas = document.createElement('canvas');
    canvas.width = edgeAlphaMapWidth;
    canvas.height = edgeAlphaMapHeight;

    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.lineWidth = CTX_LINE_WIDTH; // take a few pixels from around to capture the folding/cutting lines
    ctx.lineCap = 'round'; // TODO: see if 'square' works better?
    ctx.strokeStyle = 'white';

    // "Stopochka" algorythm
    let offsetY = ALPHA_EDGE_PADDING;
    let requiredEdgeAlphaMapHeight = 1;
    while (edgesByArea.length > 0) {
      let offsetX = ALPHA_EDGE_PADDING;
      const hoofdEdge = edgesByArea.shift();

      const edgeLine: Array<EdgeAlphaDatum> = [];
      edgeLine.push(hoofdEdge);
      offsetX += hoofdEdge.dims[X] + (ALPHA_EDGE_PADDING * 2);

      let idx = 0;
      while (idx < edgesByArea.length) {
        const candidateNextEdge = edgesByArea[idx];
        if (
            candidateNextEdge.dims[X] <= edgeAlphaMapWidth - offsetX - ALPHA_EDGE_PADDING &&
            candidateNextEdge.dims[Y] <= hoofdEdge.dims[Y]
        ) {
          edgeLine.push(candidateNextEdge);
          offsetX += candidateNextEdge.dims[X] + (ALPHA_EDGE_PADDING * 2);
          edgesByArea.splice(idx, 1);
          continue; // do not increment idx, sinse the array was changed
        }
        idx++;
      }

      // draw line of egdes
      let originX = ALPHA_EDGE_PADDING;
      let originY = offsetY;

      for (let edge of edgeLine) {
        // Paint alpha of the edge (and reverse alpha in excess)
        ctx.beginPath();
        excessCtx.beginPath();
        for (let areaId of edge.clusterAreas) {
          for (let segment of segmentsByAreaId[areaId]) {
            const x_from = originX + segment.from - edge.origin[X];
            const x_to = originX + segment.to - edge.origin[X];
            const y = originY + segment.line - edge.origin[Y];
            ctx.moveTo(x_from, y);
            ctx.lineTo(x_to, y);
            excessCtx.moveTo(segment.from, segment.line);
            excessCtx.lineTo(segment.to, segment.line);
          }
        }
        ctx.stroke();
        excessCtx.stroke();

        // set alphaBox of the edge
        modelEdgeTextureData[edge.id].alphaBox = [
          [ originX / edgeAlphaMapWidth, originY / edgeAlphaMapHeight ],
          [ (originX + edge.dims[X]) / edgeAlphaMapWidth, (originY + edge.dims[Y]) / edgeAlphaMapHeight ]
        ];

        requiredEdgeAlphaMapHeight = Math.max(requiredEdgeAlphaMapHeight, ceilPowerOfTwo(originY + edge.dims[Y]));

        originX += edge.dims[X] + (ALPHA_EDGE_PADDING * 2);
      }
      offsetY += hoofdEdge.dims[Y] + (ALPHA_EDGE_PADDING * 2);
    }

    // shrink the edgeAlphaMap height if there was too much room allocated
    const shrinkFactor = edgeAlphaMapHeight / requiredEdgeAlphaMapHeight; // will always be a power of 2
    if (shrinkFactor > 1) {
      const imgData = ctx.getImageData(0, 0, edgeAlphaMapWidth, requiredEdgeAlphaMapHeight);
      canvas.height = requiredEdgeAlphaMapHeight;
      ctx.putImageData(imgData, 0, 0);

      // recalc edge alphaBox'es
      for (const modelEdgeTextureDatum of Object.values(modelEdgeTextureData)) {
        modelEdgeTextureDatum.alphaBox[0][Y] *= shrinkFactor;
        modelEdgeTextureDatum.alphaBox[1][Y] *= shrinkFactor;
      }
    }

    const excessAlphaImage = new Image();
    excessAlphaImage.src = excessCanvas.toDataURL('image/png');
    const excessAlphaMap = new THREE.Texture(excessAlphaImage);
    excessAlphaMap.wrapS = THREE.ClampToEdgeWrapping;
    excessAlphaMap.wrapT = THREE.ClampToEdgeWrapping;
    excessAlphaMap.minFilter = excessAlphaMap.magFilter = THREE.NearestFilter;
    excessAlphaMap.needsUpdate = true;

    const edgeAlphaImage = new Image();
    edgeAlphaImage.src = canvas.toDataURL('image/png');
    const edgeAlphaMap = new THREE.Texture(edgeAlphaImage);
    edgeAlphaMap.generateMipmaps = false;
    // edgeAlphaMap.wrapS = THREE.ClampToEdgeWrapping;
    // edgeAlphaMap.wrapT = THREE.ClampToEdgeWrapping;
    //edgeAlphaMap.minFilter = THREE.LinearMipmapLinearFilter;
    edgeAlphaMap.minFilter = edgeAlphaMap.magFilter = THREE.NearestFilter;
    edgeAlphaMap.needsUpdate = true;

    const modelEdgeData: TModelEdgeData = {
      templateDims: calcTemplateDims(layout.dims),
      excessAlphaMap,
      edgeAlphaMap,
      edges: modelEdgeTextureData
    };

    // return result via the callback
    onEdgeDataLoaded(modelEdgeData);

    //console.timeEnd("loadEdgeData");
  }).catch(console.error);
};

export const calcTemplateDims = (layoutDims: Array<number>): Array<number> => {
  const ratio = layoutDims[Y] / layoutDims[X];
  const oldRatio = 3364 / 2481; // old fixed dimensions
  const w = TEMPLATE_SIZE[X];
  const h = TEMPLATE_SIZE[Y] * ratio / oldRatio;
  return [ w, h ];
}
