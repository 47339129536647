
export type TAnimationProps = {
  time: Array<number>
  mult: Array<number>
}

export type TAnimationCamera = {
  time: Array<number>
  [id: string]: Array<number>
}

export type TAnimationScenario = {
  duration: number,
  scale?: number,
  camera: TAnimationCamera
  excess: TAnimationProps
  edges: {
    [id: string]: TAnimationProps
  }
}

export type TAnimation = {
  [id: string]: TAnimationScenario
}

const scale = (timeArray: Array<number>, idx: number, factor: number) => {
  let val = timeArray[idx];
  val *= factor;
  timeArray[idx] = val;
}

const loadAnimFoldingJson = async (layoutId: string): Promise<TAnimationScenario> =>
    fetch(`/templates/${ layoutId }/anim_folding.json`).then(res => res.json());

const buildAnimations = async (layoutId: string, mirrorLayout: boolean): Promise<TAnimation> => {
  const folding: TAnimationScenario = await loadAnimFoldingJson( layoutId );

  if (mirrorLayout) {
    const cameraXs = folding['camera']['x'];
    for (let i = 0; i < cameraXs.length; i++) {
      cameraXs[i] *= -1;
    }
  }

  // scale animation duration
  if (!!folding.scale) {
    const factor = folding.scale ?? 1.0;
                  
    folding.scale = undefined; // to avoid double scaling
          
    folding.duration *= factor;
    for (let i = 0; i < folding.camera.time.length; i++) {
      scale(folding.camera.time, i, factor);
    }
    for (let i = 0; i < folding.excess.time.length; i++) {
      scale(folding.excess.time, i, factor);
    }
    for (const edgeId of Object.keys(folding.edges)) {
      const edge = folding.edges[edgeId];
      for (let i = 0; i < edge.time.length; i++) {
        scale(edge.time, i, factor);
      }
    }
  }

  return { folding };
}

export const loadAnimations = (layoutId: string, mirrorLayout: boolean, setAnimations) => {
  buildAnimations(layoutId, mirrorLayout)
      .then( setAnimations );
}
