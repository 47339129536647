import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePdfDocLoader } from "../loaders/PdfLoader";
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, Drawer, LinearProgress, makeStyles, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { Mode, Scene } from "../scene/Scene";
import './PdfVisualizer.scss';
import { OptionalContentConfig } from "pdfjs-dist/types/web/pdf_viewer";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { DynamicTexture } from "../scene/DynamicTexture";
import { REWIND_AND_PLAY_EVENT } from "../player/Player";
import { PdfConfig, PdfValidation, usePdfRenderer } from "./PdfRenderer";
import { observer } from "mobx-react-lite";


const useStyles = makeStyles(({ spacing }) => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actions: {
    display: "inline-flex",
    alignItems: "center",
  },
  dialogActions: {
    justifyContent: "space-between",
  },
  contentDivider: {
    padding: `${spacing(2)}px`,
  },
  checkboxCell: {
    border: 0,
  },
  captionKey: {
    fontSize: 10,
    fontWeight: 500,
    padding: 3,
    border: 0,
  },
  captionValue: {
    fontSize: 10,
    padding: 3,
    border: 0,
  }
}));

const PdfInfo = ({ pdfDocInfo, pdfValidations }) => {
  const classes = useStyles();

  if ( !pdfDocInfo ) return null;

  const fromAdobeDate = ( adobe: string ) : string => {
    if (adobe?.length !== 23) return;
    const ss = (start: number, length: number = 2) => adobe.substring(start, start + length);
    const DD = ss(8), MM = ss(6), YYYY = ss(2, 4),
        hh = ss(10), mm = ss(12);
    return DD+'/'+MM+'/'+YYYY+' '+hh+':'+mm;
  }
  const createData = ( name: string, value?: string | number ) => ({ name, value });

  const rows = [
    createData('Title', pdfDocInfo['Title']),
    createData('Author', pdfDocInfo['Author']),
    createData('Application', pdfDocInfo['Creator']),
    createData('PDF Producer', pdfDocInfo['Producer']),
    createData('PDF Version', pdfDocInfo['PDFFormatVersion']),
    createData('Created', fromAdobeDate( pdfDocInfo['CreationDate'] )),
    createData('Modified', fromAdobeDate( pdfDocInfo['ModDate'] )),
  ];

  const okNok = ( isOk: boolean ) => isOk
      ? <span style={{ color: 'green' }}>✔</span>
      : isOk === null ? "❔" : "❌"

  return (<>
      <Typography variant="subtitle2" style={{ paddingBottom: 4, textAlign: 'left' }}> Document Properties </Typography>
      <Table size="small" style={{ marginBottom: 10 }}>
        <TableBody>
          { rows.filter(row => !!row.value).map(row => (
              <TableRow key={ row.name }>
                <TableCell align="right" className={ classes.captionKey }>{ row.name }:</TableCell>
                <TableCell className={ classes.captionValue }>{ row.value }</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    { pdfValidations?.length && <>
      <Typography variant="subtitle2" style={{ paddingBottom: 4, textAlign: 'left' }}> Validations </Typography>
      <Table size="small" style={{ marginBottom: 10 }}>
        <TableBody>
          { pdfValidations.map(validation => (
              <TableRow key={ validation.check }>
                <TableCell className={ classes.captionValue }>{ okNok(validation.isOk) } <b>{ validation.check }</b>: { validation.result }</TableCell>
              </TableRow>
          )) }
        </TableBody>
      </Table>
    </>}
  </>);
};

interface OptionalContentGroup {
  name: string
  intent: string
  get visible(): boolean
}

const OccSection = ({ occ, onChange }: { occ: OptionalContentConfig, onChange: (parentState: number) => void }) => {
  const classes = useStyles();
  const [ , setState ] = useState( -1 );

  if ( !occ?.getGroups() ) return null;

  const groups: { [key: string]: OptionalContentGroup } = occ.getGroups();

  const isVisible = ( key ) => groups[ key ].visible;
  const groupName = ( key ) => groups[ key ].name;
  const setGroupVisibility = ( key, e ) => {
    console.log( 'Set visibility of group:', key, 'to:', e.target.checked );
    occ.setVisibility( key, e.target.checked );
    setState( Math.random() ); // toggle state change
    onChange( Math.random() );
  }

  return (<>
    <Typography variant="subtitle2" style={{ paddingBottom: 4, textAlign: 'left' }}> Layers </Typography>
    <Table size="small">
      <TableBody>
        { Object.keys( groups ).map(key => (
            <TableRow key={ key }>
              <TableCell padding="checkbox" className={ classes.checkboxCell }>
                <Checkbox
                    color="primary"
                    checked={ isVisible( key ) }
                    onChange={ e => setGroupVisibility( key, e ) }
                />
              </TableCell>
              <TableCell className={ classes.captionKey }>
                { groupName( key ) }
              </TableCell>
              {/*<TableCell className={ classes.captionValue }>{ isVisible( key ) ? 'visible' : 'hidden' }</TableCell>*/}
            </TableRow>
        ))}
      </TableBody>
    </Table>
  </>);
};

type PdfSidebarProps = {
  open : boolean
  onClose : any
  pdfConfig : PdfConfig
  onRender : Function
  isMobile : boolean
}

const PdfSidebar = observer(({ open, onClose, pdfConfig, onRender, isMobile } : PdfSidebarProps) => {
  const classes = useStyles();
  const logProgress = useCallback( (ev) => console.log('PDF loading', ev), [] );
  const { filename, pdfDoc, onFileInputChange } = usePdfDocLoader( logProgress );
  const pdfToRenderRef = useRef<PDFDocumentProxy>();
  const { preflightPdf, renderPdf } = usePdfRenderer({ pdfToRenderRef });
  const [ showFileTooltip, setShowFileTooltip ] = useState( true );
  const [ pdfDocInfo, setPdfDocInfo ] = useState<Object>();
  const [ pdfValidations, setPdfValidations ] = useState<Array<PdfValidation>>();
  const [ optionalContentConfig, setOptionalContentConfig ] = useState<OptionalContentConfig>();
  const [ , setState ] = useState( -1 );
  const [ renderingInProgress, setRenderingInProgress ] = useState( false );
  const TEX_SIZE = isMobile ? 4096 : 8192;
  const dynamicTexture = useMemo(() => new DynamicTexture(TEX_SIZE, TEX_SIZE), [ TEX_SIZE ]);

  useEffect(() => {
    if (!pdfDoc || !pdfConfig) return;
    if (pdfToRenderRef.current === pdfDoc) return;
    pdfToRenderRef.current = pdfDoc;

    const asyncRender = async (occ: OptionalContentConfig, canClose: boolean) => {
      setRenderingInProgress(true);
      try {
        await renderPdf(pdfDoc, pdfConfig, occ, dynamicTexture);
        onRender(dynamicTexture, canClose);
      } catch (e) {
        alert("Error rendering PDF");
        console.error("Error rendering PDF", e);
      }
      setRenderingInProgress(false);
    }

    preflightPdf(pdfDoc, pdfConfig).then(async ({ pdfDocInfo, occ, validations }) => {
      setPdfDocInfo(pdfDocInfo);
      setPdfValidations(validations);
      setOptionalContentConfig(occ);

      const failedValidations = validations
          .filter(v => v.isOk === false)
          .map(v => `- ${ v.check }: ${ v.result }`)
      if (failedValidations.length > 0) {
        alert('Provided PDF is not correct:\n' + failedValidations.join('\n'));
        return;
      }

      await asyncRender(occ,true);
    }).catch(console.error);
  }, [pdfDoc, pdfConfig, preflightPdf, renderPdf, dynamicTexture, onRender]);


  // useEffect(() => {
  //   const timeoutId = setTimeout(() => setShowFileTooltip( false ), 2000);
  //   return () => clearTimeout( timeoutId );
  // });

  return (
      <Drawer
          container={ window?.document?.body }
          //variant={ isMobile ? "temporary" : "persistent" }
          anchor="right"
          open={ open }
          onClose={ onClose }
          ModalProps={ isMobile ? {
            keepMounted: true, // Better open performance on mobile.
          } : undefined }
      >
        <DialogTitle style={{ minWidth: '25vw' }}>
          <Box className={ classes.titleWrapper }>
            <Typography variant="subtitle2" style={{ paddingRight: 17 }}>{ filename }</Typography>
            <Tooltip open={ showFileTooltip } title="Open a PDF file" arrow placement="left">
              <Button size="small" variant="contained" component="label" onMouseOver={ () => showFileTooltip && setShowFileTooltip( false ) }>
                <input hidden type='file' accept='application/pdf' onChange={ onFileInputChange }/>
                ...
              </Button>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent classes={ { dividers: classes.contentDivider } } dividers>
          <Box>
            <PdfInfo pdfDocInfo={ pdfDocInfo } pdfValidations={ pdfValidations } />
          </Box>
          <div style={{ height: 16 }}>
            <LinearProgress hidden={ !renderingInProgress } />
          </div>
          <Box>
            <OccSection occ={ optionalContentConfig } onChange={ setState }/>
          </Box>
        </DialogContent>
        <DialogActions classes={ { root: classes.dialogActions } }>
          <Box className={ classes.actions }>
            {/*<GoToPreviousPage>*/ }
            {/*  {(props) => (*/ }
            {/*      <IconButton disabled={props.isDisabled} onClick={props.onClick}>*/ }
            {/*        <ChevronLeftRoundedIcon />*/ }
            {/*      </IconButton>*/ }
            {/*  )}*/ }
            {/*</GoToPreviousPage>*/ }
            {/*<GoToNextPage>*/ }
            {/*  {(props) => (*/ }
            {/*      <IconButton disabled={props.isDisabled} onClick={props.onClick}>*/ }
            {/*        <ChevronRightRoundedIcon />*/ }
            {/*      </IconButton>*/ }
            {/*  )}*/ }
            {/*</GoToNextPage>*/ }
          </Box>

          <Button onClick={ onClose } color="primary">
            DISMISS
          </Button>
        </DialogActions>
      </Drawer>
  );
});

export type PdfVisualizerProps = {
  layoutId?: string,
}

export const PdfVisualizer = ({ layoutId } : PdfVisualizerProps) => {
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);
  const [ texture, setTexture ] = useState<DynamicTexture>();
  const [ pdfConfig, setPdfConfig ] = useState<PdfConfig>();
  const [ screenWidth, setScreenWidth ] = useState<number>( window.innerWidth );

  useEffect(() => {
    const handleWindowSizeChange = () => setScreenWidth( window.innerWidth );
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);
  const isMobile = screenWidth <= 768;

  useEffect(() => {
    fetch(`/templates/${ layoutId }/pdf-config.json`).then(res => res.json()).then( setPdfConfig );
  }, [ layoutId ]);

  const handleOpen = () => {
    setIsSidebarOpen(true);
  };

  const handleClose = () => {
    setIsSidebarOpen(false);
  };

  const handleRender = ( renderedTexture: DynamicTexture, canClose: boolean ) => {
    setTexture( renderedTexture );
    if (canClose) {
      setIsSidebarOpen(false);
      window.dispatchEvent(new CustomEvent(REWIND_AND_PLAY_EVENT));
    }
  }

  return (pdfConfig &&
      <>
        <Button
            color="primary"
            style={{ position: 'absolute', right: 0, margin: 15, zIndex: 1000 }}
            onClick={ handleOpen }
        >
          PDF Options
        </Button>
        <PdfSidebar
            open={ isSidebarOpen }
            onClose={ handleClose }
            pdfConfig={ pdfConfig }
            onRender={ handleRender }
            isMobile={ isMobile }
        />
        <Scene
            mode={ Mode.DEFAULT }
            suppressConsent={ true }
            layoutId={ layoutId }
            color={ '#eee' }
            { ...pdfConfig.preview }
            textureOrUrl={ texture ?? pdfConfig.preview.texture }
        />
      </>
  );
}
