import React, { Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFrame, useThree } from "react-three-fiber";
import * as THREE from "three";
import { loadLayout, TModelEdgeNode, TModelLayout, TSegmentation } from "../../loaders/LayoutLoader";
import { antiVector, interpolateCamera, interpolateMult, interpolatePos, X, Y, Z } from "../3DHelpers";
import { calcTemplateDims, loadTexture } from "../../loaders/TextureLoader";
import { TAnimationProps, TAnimationScenario } from "../../loaders/AnimationLoader";
import { SHADOWS } from '../Scene';
import { Camera } from "react-three-fiber/canvas";
import { loadFaceShapes } from "../../loaders/SvgShapeLoader";
import { EDITOR } from "../../editor/Editor";
import { ShapeSegment } from "../../three/ShapeSegment";
import { DynamicTexture } from "../DynamicTexture";

const { sign, cos, sin, tan, acos, asin, atan, abs, sqrt, PI } = Math;
const halfPi = PI / 2;


const PAPER_SHININESS = 70;
const PAPER_BACK_COLOR = 0xAAAAAA;
const PAPER_EDGE_COLOR = 0xAAAAAA;
const PAPER_THICKNESS = 0.7;
const SQUEEZE_OFFSET = 0.05;

const SEEK_DELTA_THRESHOLD = 0.0001;

type TTextures = {
  frontTexture: THREE.Texture
  backTexture?: THREE.Texture
  sbsTextureMode?: 'h' | 'v'
}

type ExcessesProps = {
  layout: TModelLayout
  textures: TTextures
  excessShapes: Array<THREE.Shape>
  animation:  TAnimationScenario
}

const Excesses = ({ layout, textures, excessShapes, animation }: ExcessesProps) => {
  return <>
    { excessShapes.map((excessShape, index) =>
        <Excess
            key={ `excess-${ index }` }
            layout={ layout }
            textures={ textures }
            excessShape={ excessShape }
            animation={ animation }
        />
    )}
  </>;
};

type ExcessProps = {
  layout: TModelLayout
  textures: TTextures
  excessShape: THREE.Shape
  animation: TAnimationScenario
}

const Excess = ({ layout, textures, excessShape, animation }: ExcessProps) => {

  const meshRef = useRef<THREE.Mesh>();
  const frontMatRef = useRef<THREE.Material>();
  const sideMatRef = useRef<THREE.Material>();
  const backMatRef = useRef<THREE.Material>();

  const geometry = useMemo(() => createFaceGeometry( excessShape, layout ), [ excessShape, layout ]);
  const animProps: TAnimationProps = animation?.excess;
  const getExtent = useCallback((elapsed) => interpolateMult(animProps, elapsed) ?? 1.0, [ animProps ]);


  useFrame(({ clock, invalidate }) => {
    if (!meshRef?.current || !frontMatRef?.current || !sideMatRef?.current || !backMatRef?.current) return false;
    const prevExtent = meshRef.current.userData['extent'];
    const extent = getExtent(clock.elapsedTime);
    if (extent === undefined || extent === prevExtent) return false;
    meshRef.current.userData['extent'] = extent;
    if (extent === 1) {
      meshRef.current.visible = false;
    } else {
      meshRef.current.visible = true;
      meshRef.current.position.z = -250 * extent;
      meshRef.current.castShadow = SHADOWS ? (extent < 0.5) : false;
      frontMatRef.current.opacity = 1 - extent;
      sideMatRef.current.opacity = 1 - extent;
      backMatRef.current.opacity = 1 - extent;
    }
    invalidate();
    return true;
  });

  return (
    <ShapeMesh
        layout={ layout }
        meshRef={ meshRef }
        frontMatRef={ frontMatRef }
        sideMatRef={ sideMatRef }
        backMatRef={ backMatRef }
        geometry={ geometry }
        frontTexture={ textures.frontTexture }
        backTexture={ textures.backTexture }
        transparent={ true }
    />
  );
};

type ModelEdgeProps = {
  modelEdge: TModelEdgeNode
  layout: TModelLayout
  textures: TTextures
  faceShapes: Map<string, THREE.Shape>
  animation: TAnimationScenario
}

const shapeSegment = (shape: THREE.Shape, segmentation: TSegmentation): THREE.Shape => {
  return new ShapeSegment( shape, segmentation.margins );
}

const createFaceGeometry = (shape: THREE.Shape, layout: TModelLayout, modelEdge?: TModelEdgeNode, sbsTextureMode?: 'h' | 'v'): THREE.BufferGeometry => {
  const paperThickness = layout.paperProps?.thickness ?? PAPER_THICKNESS;

  const geo = new THREE.ExtrudeGeometry(shape, {
    depth: paperThickness,
    // steps: 5,
    bevelEnabled: false,
    // bevelSegments: 5,
    // bevelSize: 0.5,
    // bevelThickness: 0.5
  });

  // flip Y coordinate & invert Z
  const coords = geo.attributes.position.array;
  const midPoint = [ 0, 0, 0 ];
  for (let i = 0, len = coords.length; i < len; i += 3) {
    coords[i + Y] = layout.dims[Y] - coords[i + Y];
    coords[i + Z] *= -1; // same as setting negative extrusion depth
    // calculate the midpoint
    midPoint[X] += coords[i + X];
    midPoint[Y] += coords[i + Y];
    midPoint[Z] += coords[i + Z];
  }
  midPoint[X] /= coords.length / 3;
  midPoint[Y] /= coords.length / 3;
  midPoint[Z] /= coords.length / 3;

  // squeeze the backside a bit to reduce overlapping when folding at right angle
  if (!layout.paperProps?.noSqueeze && modelEdge?.parentId && modelEdge?.foldAngle >= PI/2) {
    const extent = paperThickness * sin(modelEdge.foldAngle + SQUEEZE_OFFSET - PI/2);
    for (let i = 0, len = coords.length; i < len; i += 3) {
      if (coords[i + Z] > midPoint[Z]) continue; // not the backside
      coords[i + X] += sign(midPoint[X] - coords[i + X]) * extent;
      coords[i + Y] += sign(midPoint[Y] - coords[i + Y]) * extent;
    }
  }

  // invert the normals
  const normals = geo.attributes.normal.array;
  for (let i = 0, len = normals.length; i < len; i++) {
    normals[i] *= -1;
  }

  // normalize the UV coordinates wrt the dims & flip Y
  const uvs = geo.attributes.uv.array;
  for (let i = 0, len = uvs.length; i < len; i += 2) {
    uvs[i + X] /= layout.dims[X];
    uvs[i + Y] /= layout.dims[Y];
    uvs[i + Y] = 1 - uvs[i + Y];
  }

  // Add back-side material
  const gg = [ ...geo.groups ]; // clone array
  const half = gg[0].count / 2;
  geo.clearGroups();
  geo.addGroup(0, half, 0); // front side
  geo.addGroup(half, half, 2); // back side
  geo.addGroup(gg[1].start, gg[1].count, 1); // extrude side

  // apply SBS texture split
  if (sbsTextureMode) {
    const texScaleX = sbsTextureMode === 'h' ? 0.5 : 1;
    const texScaleY = sbsTextureMode === 'v' ? 0.5 : 1;
    const texOffsetY = sbsTextureMode === 'v' ? 0.5 : 0;
    // reshape front UVs
    for (let i = 0; i < 2 * half; i += 2) {
      uvs[i + X] *= texScaleX;
      uvs[i + Y] *= texScaleY;
      uvs[i + Y] += texOffsetY;
    }
    // reshape back UVs
    for (let i = 2 * half; i < 2 * gg[0].count; i += 2) {
      uvs[i + X] *= texScaleX;
      uvs[i + X] = 1 - uvs[i + X]; // also flip X
      uvs[i + Y] *= texScaleY;
      uvs[i + Y] += texOffsetY;
    }
  }

  return geo;
}

type ShapeMeshProps = {
  layout: TModelLayout
  meshRef?: Ref<THREE.Mesh>
  frontMatRef?: Ref<THREE.Material>
  sideMatRef?: Ref<THREE.Material>
  backMatRef?: Ref<THREE.Material>
  geometry: THREE.BufferGeometry
  frontTexture: THREE.Texture
  backTexture?: THREE.Texture
  transparent: boolean
                 
                   
          
}

const ShapeMesh = ( { layout, meshRef, frontMatRef, sideMatRef, backMatRef, geometry, frontTexture, backTexture, transparent, ...props } : ShapeMeshProps ) =>
    <mesh
        ref={ meshRef }
        geometry={ geometry }
        castShadow={ SHADOWS }
        receiveShadow={ SHADOWS }
        position={ [ 0, 0, 0.01 ] } // bump to the front a bit to reduce overlapping
                 
                    
          
    >
      <meshPhongMaterial
          ref={ frontMatRef }
          attachArray="material"
          side={ THREE.FrontSide }
          map={ frontTexture }
          transparent={ transparent }
          shineness={ layout.paperProps?.shininess ?? PAPER_SHININESS }
      />
      <meshPhongMaterial
          ref={ sideMatRef }
          attachArray="material"
          side={ THREE.FrontSide }
          color={ Number(layout.paperProps?.edgeColor ?? PAPER_EDGE_COLOR) }
          transparent={ transparent }
      />
      <meshPhongMaterial
          ref={ backMatRef }
          attachArray="material"
          side={ THREE.FrontSide }
          color={ backTexture ? null : Number(layout.paperProps?.backColor ?? PAPER_BACK_COLOR) }
          map={ backTexture }
          transparent={ transparent }
      />
    </mesh>

const ModelEdge = ({ modelEdge, layout, textures, faceShapes, animation }: ModelEdgeProps) => {
  const shifterRef = useRef<THREE.Group>();
  const folderRef = useRef<THREE.Group>();
  const { scene } = useThree();
  scene.userData[ modelEdge.id ] = { shifterRef, folderRef };
  const entangleRef = useRef<any>();

  const rotation = modelEdge.foldAxisRotation[Z] !== 0 ? modelEdge.foldAxisRotation : undefined;
  const position = [ (0.5 + modelEdge.offset[X]) * layout.dims[X], (0.5 - modelEdge.offset[Y]) * layout.dims[Y], modelEdge.offset[Z] ];

  const animId = modelEdge.segmentation?.animGroupId ?? modelEdge.id;
  const foldAnimProps: TAnimationProps = animation?.edges[animId];
  const animId_shift = animId + ".shift";
  const shiftAnimProps: TAnimationProps = animation?.edges[animId_shift];

  const getFoldExtent = useCallback((elapsed) => interpolateMult(foldAnimProps, elapsed), [ foldAnimProps ]);
  const getShiftExtent = useCallback((elapsed) => interpolateMult(shiftAnimProps, elapsed), [ shiftAnimProps ]);

  const faceShape = faceShapes.get( modelEdge.id ) ?? faceShapes.get( animId );
  const geometry = useMemo(() => {
    if (!faceShape || modelEdge.invisible) return;
    return !!modelEdge.segmentation?.margins
        ? createFaceGeometry( shapeSegment( faceShape, modelEdge.segmentation ), layout, modelEdge, textures.sbsTextureMode )
        : createFaceGeometry( faceShape, layout, modelEdge, textures.sbsTextureMode );
  }, [ modelEdge, faceShape, layout, textures.sbsTextureMode ]);

  // const deg = (rad: number) => round(rad * 180 / PI);
  // const limitRange = (arg: number) => min(max(arg, -1), 1);

  // Folding
  useFrame(({ clock, invalidate }) => {
    if (!foldAnimProps || !modelEdge.foldAngle || !folderRef.current) return false;
    const userData = folderRef.current.userData;
    const prevExtent = userData['extent'];
    const extent = getFoldExtent(clock.elapsedTime);
    if (extent === prevExtent) return false;
    const foldAngle = modelEdge.foldAngle * extent;
    if (modelEdge.eccentricity === undefined) {
      folderRef.current.rotation.x = -foldAngle;
    } else {
      const eAngle = atan(modelEdge.eccentricity * tan(foldAngle - halfPi)) + halfPi;
      folderRef.current.rotation.x = -eAngle;
    }
    userData['extent'] = extent;
    // Handle entangled edges
    if (entangleRef.current) {
      const { a, d, f, g, a2_and_b2, two_ab, betaFolderRefs, gammaFolderRefs } = entangleRef.current;
      const alfa = PI - foldAngle; // alfa is the angle inside the fold. so when the fold is 0, alfa is 180
      const cosAlfa = cos(alfa);
      const gamma = acos(f + g * cosAlfa);
      if (gammaFolderRefs) {
        const gammaFold = PI - gamma;
        gammaFolderRefs.forEach(ref => ref.current.rotation.x = -gammaFold);
      }
      // console.log(`${modelEdge.id} alfa: ${deg(alfa)}, cos(alfa): ${cosAlfa}, gamma(${modelEdge.entangle.targets[0][0]}): ${deg(gamma)}`);
      if (betaFolderRefs) {
        let betaFold;
        if (!gamma || (gamma < 0.001 && gamma > -0.001)) {
          betaFold = a < 0 ? PI : 0; // concave : convex
        } else {
          const e = sqrt(a2_and_b2 - two_ab * cosAlfa);
          const beta1 = asin(d * sin(gamma) / e);
          const beta2 = asin(a * sin(alfa) / e);
          betaFold = a < 0 ? beta1 - beta2 : PI - (beta1 + beta2); // concave : convex
          // if (!gamma || !beta1 || !beta2) console.log(`${modelEdge.id} e: ${e}, gamma: ${deg(gamma)}, beta1: ${deg(beta1)}, beta2: ${deg(beta2)}, betaFold: ${deg(betaFold)}`);
        }
        betaFolderRefs.forEach(ref => ref.current.rotation.x = -betaFold);
      }
    }
    invalidate();
    return true;
  });

  // Shifting
  useFrame(({ clock, invalidate }) => {
    if (!shiftAnimProps || !modelEdge.shiftTo || !shifterRef.current) return false;
    const prevExtent = shifterRef.current.userData['extent'];
    const extent = getShiftExtent(clock.elapsedTime);
    if (extent === prevExtent) return false;
    const newPos = interpolatePos(position, modelEdge.shiftTo, extent);
    shifterRef.current.position.copy(newPos);
    shifterRef.current.userData['extent'] = extent;
    invalidate();
    return true;
  });

  useEffect(() => {
    if (modelEdge.entangle && !entangleRef.current) {
      const [ a, b, c, d ] = modelEdge.entangle.sides;
      const f = (c*c + d*d - a*a - b*b) / (2*c*d), g = a*b / (c*d);
      const a2_and_b2 = a*a + b*b, two_ab = 2*a*b;
      const [ betaFolderRefs, gammaFolderRefs ] = modelEdge.entangle.targets.map(refIds =>
          refIds.map(id => scene.userData[id].folderRef)
      );
      entangleRef.current = { a, b, c, d, f, g, a2_and_b2, two_ab, betaFolderRefs, gammaFolderRefs };
    }
  }, [ modelEdge, entangleRef, scene ]);

  const mesh = modelEdge.invisible ? null : (
    <ShapeMesh
        layout={ layout }
        geometry={ geometry }
        frontTexture={ modelEdge.backTex ? textures.backTexture : textures.frontTexture}
        backTexture={ !!textures.sbsTextureMode ? textures.frontTexture : textures.backTexture }
        transparent={ false }
                 
                                           
                                         
                                      
                                             
          
    />
  );

  const connectedEdges = (
      <ModelEdges
          layout={ layout }
          modelEdgeTree={ modelEdge.connectedEdges }
          textures={ textures }
          faceShapes={ faceShapes }
          animation={ animation }
      />
  );

  return (
      <group ref={ shifterRef } position={ position } rotation={ rotation }>
        <group ref={ folderRef } rotation={ antiVector(rotation) }>
          <group position={ antiVector(position) }>
            { mesh }
            { connectedEdges }
          </group>
        </group>
      </group>
  );
};

type ModelEdgesProps = {
  layout: TModelLayout
  faceShapes: Map<string, THREE.Shape>
  modelEdgeTree: Array<TModelEdgeNode>
  textures: TTextures
  animation: TAnimationScenario
}

const ModelEdges = ({ layout, faceShapes, modelEdgeTree, textures, animation }: ModelEdgesProps) =>
    <>{ modelEdgeTree.map((modelEdge: TModelEdgeNode) =>
        modelEdge.id.startsWith("excess") ? (
            <Excess
                key={ modelEdge.id }
                layout={ layout }
                textures={ textures }
                excessShape={ faceShapes.get(modelEdge.id) }
                animation={ animation }
            />
            ) : (
            <ModelEdge
                key={ modelEdge.id }
                layout={ layout }
                modelEdge={ modelEdge }
                textures={ textures }
                faceShapes={ faceShapes }
                animation={ animation }
            />
        )
    ) }</>;

type ModelProps = {
  modelLayoutId: string
  mirrorLayout?: boolean
  animation: TAnimationScenario
  textureOrFile: DynamicTexture | string
  backTextureFile?: string
  sbsTextureMode?: 'h' | 'v'
}

const cameraNeedsInit = (camera: Camera): boolean => {
  if (!!camera.userData['initialized']) {
    return false;
  }
  camera.userData['initialized'] = true;
  return true;
}

export const Model2 = ({ modelLayoutId, mirrorLayout, animation, textureOrFile, backTextureFile, sbsTextureMode }: ModelProps) => {
  const [ layout, setLayout ] = useState<TModelLayout>();
  const [ modelEdgeTree, setModelEdgeTree ] = useState<Array<TModelEdgeNode>>();
  useEffect(() => loadLayout( modelLayoutId, setLayout, setModelEdgeTree ), [ modelLayoutId ]);

  const [ faceShapes, setFaceShapes ] = useState<Map<string, THREE.Shape>>();
  const [ excessShapes, setExcessShapes ] = useState<Array<THREE.Shape>>();
  useEffect(() => loadFaceShapes( modelLayoutId, setFaceShapes, setExcessShapes ), [ modelLayoutId ]);

  const [ templateDims, setTemplateDims ] = useState<Array<number>>();
  useEffect(() => !!layout && setTemplateDims(calcTemplateDims(layout.dims)), [ layout ]);

  const [ frontTexture, setFrontTexture ] = useState<THREE.Texture>();
  useEffect(() => loadTexture( textureOrFile, mirrorLayout, setFrontTexture ), [ textureOrFile, mirrorLayout ]);

  const [ backTexture, setBackTexture ] = useState<THREE.Texture>();
  useEffect(() => loadTexture( backTextureFile, !mirrorLayout, setBackTexture ), [ backTextureFile, mirrorLayout ]);

  // Seeking
  useFrame(({ clock, camera, invalidate }) => {
    if (!camera?.userData['seeking']) return false;
    const seekTime = camera.userData['seekTime'];
    if (clock.elapsedTime === seekTime) return false;
    if (abs(clock.elapsedTime - seekTime) < SEEK_DELTA_THRESHOLD) {
      clock.elapsedTime = seekTime;
    } else {
      clock.elapsedTime = (seekTime * 0.1) + (clock.elapsedTime * 0.9);
    }
    invalidate();
    return true;
  });

  // Camera animation
  useFrame(({ clock, camera, invalidate }) => {
    if (!camera || !!camera.userData['under-control'] || !animation?.camera) return false;
    const needsInit = cameraNeedsInit(camera);
    if (!clock?.running && !camera.userData['seeking'] && !needsInit) return false;
    const elapsed = clock.elapsedTime;
    const newPos = interpolateCamera(animation.camera, camera.position, elapsed);
    if (camera.position.equals(newPos)) return false;
    camera.position.copy(newPos);
    camera.lookAt(0, 0, 0);
    invalidate();
    return true;
  });

  if (!layout || !faceShapes || !templateDims || !frontTexture || (backTextureFile && !backTexture)) return null;

  const zoom = (layout.zoom ?? 1) * 0.01;
  const scale = [ mirrorLayout ? -zoom : zoom, zoom, zoom ];
  const centered = [ -layout.dims[X] * scale[X] / 2, -layout.dims[Y] * scale[Y] / 2, 0 ];

  return (
      <group
          position={ centered }
          scale={ scale }
      >
        <Excesses
            layout={ layout }
            textures={{ frontTexture, backTexture, sbsTextureMode }}
            excessShapes={ excessShapes }
            animation={ animation }
        />
        <ModelEdges
            modelEdgeTree={ modelEdgeTree }
            layout={ layout }
            textures={{ frontTexture, backTexture, sbsTextureMode }}
            faceShapes={ faceShapes }
            animation={ animation }
        />
      </group>
  );
};
