import * as THREE from "three";
import { SVGLoader } from "../three/SVGLoader";


export const loadShapePaths = (svgUrl: string, onShapePathsLoaded: (shapePaths: Array<THREE.ShapePath>) => void) =>
  new SVGLoader().load(svgUrl, data => onShapePathsLoaded(data.paths));

export const loadFaceShapes = (
    layoutId: string,
    onFaceShapesLoaded: (faceShapeMap: Map<string, THREE.Shape>) => void,
    onExcessShapesLoaded: (excessShapes: Array<THREE.Shape>) => void
) => {
  loadShapePaths(`/templates/${ layoutId }/shapes.svg`, (shapePaths: Array<THREE.ShapePath>) => {
    const faceShapeMap = new Map<string, THREE.Shape>();
    const excessShapes = new Array<THREE.Shape>();

    shapePaths.forEach( (path: THREE.ShapePath) => {
      const faceId = path.userData.node?.id;
      if (!faceId) return;
      const pathShapes: Array<THREE.Shape> = path.toShapes();

      if ( faceId === 'excess' ) {
        excessShapes.push(...pathShapes);
      } else {
        console.assert( pathShapes.length === 1 );
        const shape: THREE.Shape = pathShapes[0];
        faceShapeMap.set(faceId, shape);
      }
    });

    onFaceShapesLoaded( faceShapeMap );
    onExcessShapesLoaded( excessShapes );
  });
}
