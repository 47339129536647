import { MuiThemeProvider } from "@material-ui/core/styles";
import ThreeDRotationRoundedIcon from "@material-ui/icons/ThreeDRotationRounded";
import React from "react";
import { MUI_THEME } from "../player/Player";
import "./ThreeDeeManipulationHints.scss";

export const ThreeDeeManipulationHints = () => (
    <MuiThemeProvider theme={ MUI_THEME }>
      <div className="three-dee-manipulation-icons">
        <ThreeDRotationRoundedIcon htmlColor="#efefef" fontSize="large"/>
      </div>
    </MuiThemeProvider>
);
