import { PI, X, Y, Z } from "../scene/3DHelpers";

export type TModelLayout = {
  dims: Array<number>
  zoom?: number
  paperProps?: TPaperProps
  modelEdges: Array<TModelEdgeLayout>
}

export type TPaperProps = {
  shininess?: number
  backColor?: string
  edgeColor?: string
  thickness?: number
  noSqueeze?: boolean
}

export type TModelEdgeLayout = {
  id: string
  color?: string
  parentId?: string
  invisible?: boolean
  backTex?: boolean
  pointWithin?: Array<number>
  shiftTo?: Array<number>
  shiftRel?: Array<number>
  axisPoints: Array<Array<number>>
  foldAngleDeg: number
  eccentricity?: number
  bendExtent?: number
  entangle? : {
    sides : Array<number>,
    targets : Array<Array<string>>
  }
}

export type TSegmentation = {
  animGroupId: string
  margins?: Array<Array<number>>
}

export type TModelEdgeNode = {
  id: string
  parentId?: string
  invisible?: boolean
  backTex?: boolean
  offset: Array<number>
  shiftTo?: Array<number>
  foldAxisRotation: Array<number>
  foldAngle: number
  eccentricity?: number
  bendExtent?: number
  segmentation?: TSegmentation
  connectedEdges?: Array<TModelEdgeNode>
  entangle? : {
    sides : Array<number>,
    targets : Array<Array<string>>
  }
}

export const loadLayoutJson = async (layoutId: string): Promise<TModelLayout> =>
    fetch(`/templates/${ layoutId }/layout.json`).then(res => res.json());

const NOOP_VECTOR3 = [ 0, 0, 0 ];

const calculateOffset = (layout: TModelLayout, axisPoints: Array<Array<number>>): Array<number> => {
  if (!axisPoints) return NOOP_VECTOR3;
  const dims = layout.dims, axisPoint = axisPoints[0];
  return [ axisPoint[X] / dims[X] - 0.5, axisPoint[Y] / dims[Y] - 0.5, 0 ];
};

const calculateShift = (layout: TModelLayout, shiftTo?: Array<number>): Array<number> => {
  if (!shiftTo) return undefined;
  const dims = layout.dims;
  return [ shiftTo[X] + dims[X] * 0.5, shiftTo[Y] + dims[Y] * 0.5, shiftTo[Z] ];
};

const calculateShiftRel = (layout: TModelLayout, axisPoints: Array<Array<number>>, shiftRel?: Array<number>, segmentCount: number = 1): Array<number> => {
  if (!shiftRel) return undefined;
  const dims = layout.dims, axisPoint = axisPoints[0];
  return [ shiftRel[X] + axisPoint[X], dims[Y] - (shiftRel[Y] / segmentCount + axisPoint[Y]), shiftRel[Z] ];
};

const calculateFoldAxisRotation = (layout: TModelLayout, axisPoints: Array<Array<number>>): Array<number> => {
  if (!axisPoints || axisPoints.length < 2) return NOOP_VECTOR3;
  const dx = (axisPoints[1][X] - axisPoints[0][X]);
  const dy = (axisPoints[1][Y] - axisPoints[0][Y]);
  const foldAxisAngle = Math.atan2(-dy, dx);
  return [ 0, 0, foldAxisAngle ];
};

const mapModelEdge = (layout: TModelLayout, edgeLayout: TModelEdgeLayout) => {
  const id = edgeLayout.id;
  const segmentation: TSegmentation = id.includes('[') && ({
    animGroupId: id.split('[')[0]
  });
  const shiftTo = edgeLayout.shiftRel
      ? calculateShiftRel(layout, edgeLayout.axisPoints, edgeLayout.shiftRel)
      : calculateShift(layout, edgeLayout.shiftTo);
  const modelEdge: TModelEdgeNode = {
    id,
    parentId: edgeLayout.parentId,
    invisible: edgeLayout.invisible,
    backTex: edgeLayout.backTex,
    offset: calculateOffset(layout, edgeLayout.axisPoints),
    shiftTo,
    foldAxisRotation: calculateFoldAxisRotation(layout, edgeLayout.axisPoints),
    foldAngle: edgeLayout.foldAngleDeg * PI / 180,
    eccentricity: edgeLayout.eccentricity,
    bendExtent: edgeLayout.bendExtent,
    entangle: edgeLayout.entangle,
    segmentation
  };
  return modelEdge;
}

const DEG_PER_SEGMENT = 3;

const mapModelEdgeArray = (layout: TModelLayout, edgeLayout: TModelEdgeLayout) => {
  const edgeArray = new Array<TModelEdgeNode>();
  const count = Math.ceil(edgeLayout.foldAngleDeg / DEG_PER_SEGMENT);

  const bendExtent = edgeLayout.bendExtent ?? 1;

  const gamma = edgeLayout.foldAngleDeg * PI / 180;
  const dgamma = gamma / bendExtent / count;
  const ogamma = gamma * (1 - (1 / bendExtent)) / 2;

  const aps = edgeLayout.axisPoints;
  const x0 = aps[0][X], x1 = aps[1][X], y0 = aps[0][Y], y1 = aps[1][Y];
  const dx0 = (aps[2][X] - aps[0][X]) / count, dx1 = (aps[3][X] - aps[1][X]) / count;
  const dy0 = (aps[2][Y] - aps[0][Y]) / count, dy1 = (aps[3][Y] - aps[1][Y]) / count;

  let accuAngle = 0;

  for (let index = 0; index <= count; index++) {
    const margins = [ // TODO: this only supports linear interpolation
      [x0 + dx0 * index, y0 + dy0 * index], [x1 + dx1 * index, y1 + dy1 * index],
      [x0 + dx0 * (index + 1), y0 + dy0 * (index + 1)], [x1 + dx1 * (index + 1), y1 + dy1 * (index + 1)],
    ];

    const isFirst = index === 0, isLast = index === count;

    const cos0 = Math.cos(index * dgamma), cos1 = Math.cos((index + 1) * dgamma);
    const dh = Math.asin((cos0 - cos1) / dgamma);
    let foldAngle = ((cos1 < 0 ? PI - dh : dh) - accuAngle) * (isLast ? 0.5 : 1);
    if (foldAngle < 0) foldAngle += isLast ? PI : 2 * PI;

    accuAngle += foldAngle;

    const modelEdge: TModelEdgeNode = {
      id: isLast ? edgeLayout.id : `${edgeLayout.id}[${index}]`,
      parentId: isFirst ? edgeLayout.parentId : `${edgeLayout.id}[${index - 1}]`,
      invisible: isLast,
      backTex: edgeLayout.backTex,
      offset: calculateOffset(layout, margins),
      shiftTo: isLast ? undefined : calculateShiftRel(layout, margins, edgeLayout.shiftRel, count),
      foldAxisRotation: calculateFoldAxisRotation(layout, margins),
      foldAngle: isFirst || isLast ? foldAngle + ogamma : foldAngle,
      segmentation: isLast ? undefined : { animGroupId: edgeLayout.id, margins }
    };
    edgeArray.push(modelEdge);
  }

  return edgeArray;
}

const buildModelEdgeArray = (layout: TModelLayout): Array<TModelEdgeNode> => {
  const modelEdges: Array<TModelEdgeNode> = layout.modelEdges
      .map((edgeLayout: TModelEdgeLayout) => edgeLayout.axisPoints?.length === 4
          ? mapModelEdgeArray(layout, edgeLayout)
          : mapModelEdge(layout, edgeLayout)
      )
      .flat(1);
  return modelEdges;
};

const buildModelEdgeTree = (modelEdges: Array<TModelEdgeNode>, parentEdgeId?: string): Array<TModelEdgeNode> => {
  return modelEdges
      .filter((modelEdge: TModelEdgeNode) => modelEdge.parentId === parentEdgeId)
      .map((modelEdge: TModelEdgeNode) => {
        modelEdge.connectedEdges = buildModelEdgeTree(modelEdges, modelEdge.id);
        return modelEdge;
      });
};

export const loadLayout = (layoutId: string, setLayout, setModelEdges) => {
  loadLayoutJson(layoutId)
      .then(layoutJson => {
        const modelEdges = buildModelEdgeTree(buildModelEdgeArray(layoutJson));
        setLayout(layoutJson);
        setModelEdges(modelEdges);
      });
}
