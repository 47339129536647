import React, { useEffect, useRef } from 'react'
import { addEffect, extend, ReactThreeFiber, useThree } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Vector3 } from "react-three-fiber/three-types";

// Extend will make OrbitControls available as a JSX element called orbitControls for us to use.
extend({ OrbitControls });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<OrbitControls, typeof OrbitControls>
    }
  }
}

                 
                                     
                       
                               
                                                     
                                             
   
                                                   
 
          

export const Controls = ({ ...props }) => {
  const controls = useRef<OrbitControls>();
  const { camera, clock, gl, invalidate } = useThree();

  addEffect(() => !clock.running && controls.current?.update());

  const onStartControlMovement = () => {
    camera.userData['under-control'] = true;
    invalidate();
  };
  const onEndControlMovement = () => {
                 
                            
          
    camera.userData['under-control'] = false;
    invalidate();
  };
  const onControlMovementInProgress = () => {
    invalidate();
  };

  useEffect(() => {
    const currentControls = controls.current;
    if (currentControls) {
      currentControls.addEventListener("start", onStartControlMovement);
      currentControls.addEventListener("end", onEndControlMovement);
      currentControls.addEventListener('change', onControlMovementInProgress);
      return () => {
        currentControls.removeEventListener("start", onStartControlMovement);
        currentControls.removeEventListener("end", onEndControlMovement);
        currentControls.removeEventListener('change', onControlMovementInProgress);
      }
    }
  });

  return (
      <orbitControls
          ref={ controls }
          args={ [ camera, gl.domElement ] }
          enableDamping dampingFactor={ 0.1 }
          enablePan={ false }
          minDistance={ 5 }
          maxDistance={ 30 }
          // rotateSpeed={ 0.5 }
          // maxPolarAngle={ Math.PI/2 }
          { ...props }
      />
  );
};
