import { MutableRefObject } from "react";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { OptionalContentConfig } from "pdfjs-dist/types/web/pdf_viewer";
import { DynamicTexture } from "../scene/DynamicTexture";
import { X, Y } from "../scene/3DHelpers";


//const TEX_SIZE = 8192; // TODO: use https://github.com/jhildenbiddle/canvas-size

type PageRegionMapping = {
  src: Array<number>
  dest: Array<number>
}

export type PdfConfig = {
  preview: {
    version: number
    texture: string
    sbsTextureMode?: 'h' | 'v'
    folded?: boolean
    color?: string
  }
  hiddenLayers: Array<string>
  requirements: {
    numPages: number
    pageDims: {
      [pageId: string]: Array<number>
    }
    bleed?: number
    pdfVersion?: Array<string>
    gtsPdfXVersion?: Array<string>
    gtsPdfXConformance?: Array<string>
  }
  pages: {
    [pageId: string]: {
      port: Array<number>
      maps: Array<PageRegionMapping>
    }
  }
  maps: {
    [pageId: string]: Array<PageRegionMapping>
  }
}

export type PdfValidation = {
  isOk: boolean
  check: string
  result: string
}

type PdfInfo = {
  pdfDocInfo?: Object
  occ?: OptionalContentConfig
}

const _toPageCount = ( numPages: number ) => numPages === 1 ? "1 page" : `${ numPages } pages`

const _toMm = ( points: number ) => Math.round( points * 0.352778 );

const _preflightPdf = async ( pdfDoc: PDFDocumentProxy, pdfConfig: PdfConfig, pdfInfo: PdfInfo, validations: PdfValidation[] ) => {
  if ( !pdfDoc ) return; // no document
  let isOk: boolean, check: string;

  const infoAndMetadata = await pdfDoc.getMetadata();
  console.log('Info & Metadata:', infoAndMetadata);
  pdfInfo.pdfDocInfo = infoAndMetadata.info;

  const reqs = pdfConfig.requirements;
  if (!!reqs.pdfVersion) {
    const filePdfVersion = infoAndMetadata.info['PDFFormatVersion'];
    check = "PDF version";
    isOk = reqs.pdfVersion.includes(filePdfVersion) || null;
    validations.push({ isOk, check, result: isOk
          ? filePdfVersion
          : `${ filePdfVersion } is not supported. Required version: ${ reqs.pdfVersion.join(' or ') }.`
    });
  }

  if (!!reqs.gtsPdfXVersion) {
    const fileGtsPdfXVersion = infoAndMetadata.info['Custom']?.['GTS_PDFXVersion'] || 'Unknown';
    check = "PDF standard";
    isOk = reqs.gtsPdfXVersion.includes(fileGtsPdfXVersion) || null;
    validations.push({ isOk, check, result: isOk || isOk === null
          ? fileGtsPdfXVersion
          : `${ fileGtsPdfXVersion } is not correct. Expected: ${ reqs.gtsPdfXVersion.join(' or ') }.`
    });
  }

  if (!!reqs.gtsPdfXConformance) {
    const fileGtsPdfXConformance = infoAndMetadata.info['Custom']?.['GTS_PDFXConformance'] || 'Unknown';
    check = "PDF/X conformance";
    isOk = reqs.gtsPdfXConformance.includes(fileGtsPdfXConformance) || null;
    validations.push({ isOk, check, result: isOk || isOk === null
          ? fileGtsPdfXConformance
          : `${ fileGtsPdfXConformance } is not correct. Expected: ${ reqs.gtsPdfXConformance.join(' or ') }.`
    });
  }

  check = "Number of pages";
  isOk = pdfDoc.numPages === reqs.numPages;
  validations.push({ isOk, check, result: isOk
        ? _toPageCount( pdfDoc.numPages )
        : `The file contains ${ _toPageCount( pdfDoc.numPages ) }, but required to have ${ _toPageCount( reqs.numPages ) }`
  });
  if (!isOk) return;

  for (const pageKey of Object.keys( pdfConfig.pages )) {
    const page = await pdfDoc.getPage(Number(pageKey));
    const vp = page.getViewport({ scale: 1 });
    check = `Page ${ pageKey } dimensions`;
    const reqDims = reqs.pageDims?.[ pageKey ];
    const bleed = reqDims && Math.min( _toMm(vp.width) - reqDims[0], _toMm(vp.height) - reqDims[1] ) / 2;
    const reqBleed = reqs.bleed || 0;
    isOk = reqDims ? (bleed || 0) >= reqBleed : null;
    const bleedStr = reqDims && (bleed >= 0) ? ` (bleed: ${ bleed }mm)` : '';
    validations.push({ isOk, check, result: isOk || isOk === null
          ? `${ _toMm(vp.width) }mm x ${ _toMm(vp.height) }mm${ bleedStr }`
          : (vp.width < reqDims[0] || vp.height < reqDims[1])
              ? `${ _toMm(vp.width) }mm x ${ _toMm(vp.height) }mm, required ${ reqDims[0] }mm x ${ reqDims[1] }mm`
              : `${ _toMm(vp.width) }mm x ${ _toMm(vp.height) }mm${ bleedStr }, required bleed at least ${ reqBleed }mm`
    });
  }

  const occ = await pdfDoc.getOptionalContentConfig();
  console.log('OptionalContentConfig:', occ);
  const groups = occ.getGroups();
  console.log('OptionalContentConfig.groups:', groups);
  !!groups && !!pdfConfig.hiddenLayers && Object.keys(groups).forEach(key => {
    const groupName = groups[key]?.name?.toLowerCase() || '';
    if (pdfConfig.hiddenLayers.some(hl => groupName.includes( hl ))) {
      occ.setVisibility(key, false);
    }
  });
  pdfInfo.occ = occ;
}

const _renderPdf = async (
    pdfDoc: PDFDocumentProxy, pdfToRenderRef: MutableRefObject<PDFDocumentProxy>, pdfConfig: PdfConfig, occ: OptionalContentConfig,
    dynamicTexture: DynamicTexture
) => {
  const texSize = dynamicTexture.getCanvas().width;

  const renderTasks = Object.entries( pdfConfig.pages ).map(async ([pageKey, pageConfig]) => {
    console.log('Page', pageKey, '- Prepare rendering');

    const canvas = document.createElement('canvas' );
    const page = await pdfDoc.getPage(Number(pageKey));
    const defaultViewport = page.getViewport({ scale: 1 });
    console.log('Page', pageKey, '- defaultViewport', defaultViewport);

    // Re-scale to 4k/8k
    const scale = texSize / Math.max(pageConfig.port[X], pageConfig.port[Y]);
    const offsetX = -scale * (defaultViewport.width - pageConfig.port[X]) / 2;
    const offsetY = -scale * (defaultViewport.height - pageConfig.port[Y]) / 2;
    const viewport = page.getViewport({ scale, offsetX, offsetY });
    canvas.width = Math.floor(viewport.width + (offsetX * 2));
    canvas.height = Math.floor(viewport.height + (offsetY * 2));
    const canvasContext = canvas.getContext("2d", { willReadFrequently: true });

    const renderTask = page.render({
      canvasContext,
      viewport,
      optionalContentConfigPromise: Promise.resolve( occ )
    });

    renderTask.onContinue = (continueRendering: Function) => {
      if ( pdfToRenderRef.current !== pdfDoc ) {
        console.log('Page', pageKey, '- Concurrent rendering task detected. Cancelling current rendering.');
        renderTask.cancel();
      } else {
        console.log('Page', pageKey, '- Continue rendering...');
        continueRendering();
      }
    }

    await renderTask.promise;
    console.log('Page', pageKey, '- Rendering complete');

    // Apply mapping
    const destCtx = dynamicTexture.getContext();
    const [ sw, sh ] = [ canvas.width, canvas.height ];
    const [ dw, dh ] = [ texSize, texSize ];
    pageConfig.maps.forEach(({ src, dest }) => {
      destCtx.clearRect(dest[0]*dw, dest[1]*dh, dest[2]*dw, dest[3]*dh);
      destCtx.drawImage(canvas, src[0]*sw, src[1]*sh, src[2]*sw, src[3]*sh, dest[0]*dw, dest[1]*dh, dest[2]*dw, dest[3]*dh);
    });
    console.log('Page', pageKey, '- Mapped to the texture');
  });

  try {
    await Promise.all( renderTasks );
    console.log("Renedering complete");
    dynamicTexture.invalidate();

  } catch ( err ) {
    if (err?.name === 'RenderingCancelledException') {
      console.log( err.message );
    } else {
      console.error("Rendering stopped due to error", err);
    }
    throw err;
  }
}

type PdfRendererProps = {
  pdfToRenderRef: MutableRefObject<PDFDocumentProxy>
}

export const usePdfRenderer = ({ pdfToRenderRef } : PdfRendererProps) => {

  const preflightPdf = async ( pdfDoc : PDFDocumentProxy, pdfConfig: PdfConfig ) => {
    const pdfInfo : PdfInfo = {}, validations: Array<PdfValidation> = [];

    try {
      await _preflightPdf( pdfDoc, pdfConfig, pdfInfo, validations );
    } catch (e) {
      validations.push({ isOk: false, check: "Error loading PDF", result: "Unexpected error" });
      console.error("Unexpected error loading PDF", e);
    }

    return { pdfDocInfo: pdfInfo.pdfDocInfo, occ: pdfInfo.occ, validations };
  }

  const renderPdf = ( pdfDoc : PDFDocumentProxy, pdfConfig: PdfConfig, occ: OptionalContentConfig, dynamicTexture: DynamicTexture) =>
      _renderPdf(pdfDoc, pdfToRenderRef, pdfConfig, occ, dynamicTexture);

  return { preflightPdf, renderPdf };
};
