import React, { useEffect, useState } from "react";
import { firebaseApp } from "../../firebase-config";
import { getAnalytics } from "firebase/analytics";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Link, MuiThemeProvider } from "@material-ui/core";
import { MUI_THEME } from "../player/Player";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";

const LS_KEY = "cookie-consent-given";

const getRegisteredConsent = () => {
  const consent = localStorage?.getItem(LS_KEY);
  return !!consent;
}

const registerConsent = ( setConsent ) => {
  localStorage?.setItem(LS_KEY, Date());
  setConsent( true );
}

const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    backdropFilter: "blur(3px)"
  },
}));

export const CookieConsent = ({ supress } : { supress : boolean }) => {
  const [ consent, setConsent ] = useState( getRegisteredConsent() );
  const classes = useStyles(MUI_THEME);

  useEffect(() => {
    if (consent) {
      getAnalytics( firebaseApp );
    }
  }, [ consent ]);

  return (supress || consent ? null :
          <MuiThemeProvider theme={ MUI_THEME }>
            <Dialog
                BackdropProps={{ classes }}
                open={ true }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  We gebruiken cookies en vergelijkbare technologieën om de diensten en functionaliteit op onze site mogelijk te maken
                  en om je interactie met onze dienst te begrijpen.
                  Door op 'OK' te klikken, ga je akkoord met ons gebruik van dergelijke technologieën voor marketing en analyse.
                </DialogContentText>
              </DialogContent>
              <DialogActions style={ { margin: 12, marginTop: 0 } }>
                <Typography variant="body2" style={ { alignSelf: "flex-end" } }>
                  <Link href="https://www.vouwauto.nl/privacy-beleid" target="_blank" color="secondary">
                    Privacy Beleid
                  </Link>
                </Typography>
                <Button
                    variant="outlined"
                    style={ { marginLeft: "auto", color: "#319FE4" } }
                    onClick={ () => registerConsent(setConsent) }
                    // autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </MuiThemeProvider>
  );
}
