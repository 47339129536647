import './ReviewPanel.scss'
import React, { useState } from 'react'
import reviewPanelJaImage from "../../assets/images/review-panel-ja.png";
import reviewPanelNeeImage from "../../assets/images/review-panel-nee.png";

export const ReviewPanel = ({ projectTitle }) => {
  const [ response, setResponse ] = useState(true);

  const email = 'akkoord@vouwauto.nl';
  const subject = response
      ? encodeURIComponent('JA - ' + projectTitle)
      : encodeURIComponent('NEE - ' + projectTitle);
  const body = response
      ? encodeURIComponent('Hé Vouwers!\n\nZojuist heb ik jullie eenvouwdig fantastische conceptvoorstel aandachtig doorgenomen en hierbij geef ik mijn akkoord op dit ontwerp!\n\n')
      : encodeURIComponent('Hé Vouwers!\n\nZojuist heb ik jullie conceptvoorstel aandachtig doorgenomen en helaas zag ik nog een paar “vouwtjes”.\nHier beneden heb ik mijn correcties heel eenvouwdig samengevat:\n\n');

  const emailLink = `mailto:${ email }?subject=${ subject }&body=${ body }`;

  return (
      <div className="review-panel">
        <img alt="review buttons"
             className="review-image"
             src={ response ? reviewPanelJaImage : reviewPanelNeeImage }
             useMap="#review-map"
        />
        <map name="review-map">
          <area alt="ja" shape="rect" coords="71,31,199,60" href="#" onClick={ () => (!response) && setResponse(true) }/>
          <area alt="nee" shape="rect" coords="52,60,199,88" href="#" onClick={ () => (response) && setResponse(false) }/>
          <area alt="email" shape="rect" coords="127,92,200,120" href={ emailLink } target="_blank" rel="noreferrer"/>
        </map>
      </div>
  );
};