import * as THREE from "three";
import { invalidate } from "react-three-fiber";

export class DynamicTexture {
  private readonly canvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D;
  private readonly texture: THREE.Texture;

  constructor(width: number, height: number) {
    this.canvas = document.createElement('canvas');
    this.canvas.width = width;
    this.canvas.height = height;
    this.ctx = this.canvas.getContext('2d');
    this.texture = new THREE.CanvasTexture(this.canvas);
    this.texture.magFilter = THREE.LinearFilter;
    this.texture.minFilter = THREE.LinearMipMapLinearFilter;
    this.texture.wrapS = THREE.ClampToEdgeWrapping;
    this.texture.wrapT = THREE.ClampToEdgeWrapping;
    this.texture.anisotropy = 16;
    this.texture.needsUpdate = true;
  }

  public getTexture() {
    return this.texture;
  }

  public invalidate() {
    this.texture.needsUpdate = true;
    invalidate();
  }

  public getContext() {
    return this.ctx;
  }

  public getCanvas() {
    return this.canvas;
  }
}
