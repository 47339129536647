import { Shape, Vector2 } from "three";
import { X, Y } from "../scene/3DHelpers";

type RtmInfo = {
  rtmA: number
  rtmB: number
  inside: boolean
}

export class ShapeSegment extends Shape {

  private readonly shape: Shape;
  private readonly margins: Array<Array<number>>;

  constructor( shape: Shape, margins: Array<Array<number>> ) {
    super();
    this.shape = shape;
    this.margins = margins;
    // Ensure closkwise order
    if ((margins[1][X] - margins[0][X])*(margins[3][Y] - margins[0][Y]) < (margins[1][Y] - margins[0][Y])*(margins[3][X] - margins[0][X])) {
      this.margins = [ margins[1], margins[0], margins[3], margins[2] ];
    }
  }

  public extractPoints( divisions: number ): {
    shape: Vector2[];
    holes: Vector2[][]; // TODO: holes are currently not supported
  } {
    const extractedPoints = this.shape.extractPoints( divisions );
    const shapePoints = extractedPoints.shape;
    const segmentPoints = new Array<Vector2>();

    const relationToMargin = ( m1: Array<number>, m2: Array<number>, p: Vector2 ): number =>
      ((m2[X] - m1[X])*(p.y - m1[Y]) - (m2[Y] - m1[Y])*(p.x - m1[X]));

    const relToMargins = ( p: Vector2 ): RtmInfo => {
      const rtmA = relationToMargin(this.margins[0], this.margins[1], p);
      const rtmB = relationToMargin(this.margins[2], this.margins[3], p);
      const inside = rtmA * rtmB <= 0;
      return { rtmA, rtmB, inside };
    }

    const intersectionPoint = ( m1: Array<number>, m2: Array<number>, p1: Vector2, p2: Vector2 ): Vector2 => {
      const a1 = m2[Y] - m1[Y], b1 = m1[X] - m2[X], c1 = a1 * m1[X] + b1 * m1[Y];
      const a2 = p2.y  - p1.y,  b2 = p1.x  - p2.x,  c2 = a2 * p1.x  + b2 * p1.y;
      const det = a1 * b2 - a2 * b1;
      //console.assert( det !== 0 );
      const x = ((b2 * c1) - (b1 * c2)) / det, y = ((a1 * c2) - (a2 * c1)) / det;
      return new Vector2( x, y );
    }

    let last: Vector2 = shapePoints[shapePoints.length - 1], lastPtm: RtmInfo = relToMargins(last);
    shapePoints.forEach((point, index) => {
      const ptm: RtmInfo = relToMargins( point );
      if (!!last && (!lastPtm.inside || !ptm.inside)) {
        if (lastPtm.rtmA * ptm.rtmA < 0 && ptm.rtmA > 0) {
          segmentPoints.push( intersectionPoint( this.margins[0], this.margins[1], last, point ) );
        }
        if (lastPtm.rtmB * ptm.rtmB < 0) {
          segmentPoints.push( intersectionPoint( this.margins[2], this.margins[3], last, point ) );
        }
        if (lastPtm.rtmA * ptm.rtmA < 0 && ptm.rtmA < 0) {
          segmentPoints.push( intersectionPoint( this.margins[0], this.margins[1], last, point ) );
        }
      }
      if (ptm.inside) {
        segmentPoints.push(point);
      }
      last = point;
      lastPtm = ptm;
    });

    // close the segment shape if not closed
    if (segmentPoints.length > 0 && !segmentPoints[ segmentPoints.length - 1 ].equals( segmentPoints[0] )) {
      segmentPoints.push( segmentPoints[0] );
    }

    // console.log(segmentPoints); // TODO: debug

    return { shape: segmentPoints, holes: extractedPoints.holes };
  }
}
