import { Loader } from "three";
import * as PdfJsLib from "pdfjs-dist/types/src/pdf";
import { ChangeEventHandler, useCallback, useState } from "react";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";

type PdfJsLibType = typeof PdfJsLib;

let PDFJS_SINGLETON: PdfJsLibType;

const getPdfJsInstance = async () => {
  if (!PDFJS_SINGLETON) {
    // We import this here so that it's only loaded during client-side rendering.
    const pdfjsInstance = await import('pdfjs-dist/moz/pdf-es5-fix') as PdfJsLibType;
    pdfjsInstance.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.js';
    PDFJS_SINGLETON = pdfjsInstance;
  }
  return PDFJS_SINGLETON;
}

export const usePdfDocLoader = (onProgress?: Function) => {
  const [ filename, setFilename ] = useState(null);
  const [ pdfDoc, setPdfDoc ] = useState<PDFDocumentProxy>(null);

  const onFileInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
    const file = ev?.target?.files?.[0];
    if ( !file ) return;

    setFilename( null );
    setPdfDoc( null );

    const fileReader = new FileReader();

    fileReader.onload = (async ev => {
      const pdfJs = await getPdfJsInstance();
      const pdfLoadTask = pdfJs.getDocument( ev.target.result );
      pdfLoadTask.onProgress = onProgress;
      const pdfDocProxy = await pdfLoadTask.promise;
      setFilename( file.name );
      setPdfDoc( pdfDocProxy );
    });

    fileReader.readAsArrayBuffer( file );
  }, [ onProgress ] );

  return { filename, pdfDoc, onFileInputChange };
}

export class PdfLoader extends Loader {

  load(
      url: string,
      onLoad?: ( canvas: HTMLCanvasElement ) => void,
      onProgress?: ( event: ProgressEvent ) => void,
      onError?: ( event: ErrorEvent ) => void
  ) {
    console.log('Loading', url);
    const canvas = document.createElement('canvas' );

    getPdfJsInstance().then(async pdfJs => {
      const pdf = await pdfJs.getDocument( url ).promise;

      // const pdfLoadingTask = pdfJs.getDocument(url);
      // let lastOnProgress = new Date().getTime();
      // pdfLoadingTask.onProgress = data => {
      //   console.log("onProgress:", data);
      //   lastOnProgress = new Date().getTime();
      // }
      // const pdf = await pdfLoadingTask.promise;
      //
      // // wait for 3 seconds for the new onProgress events
      // while (lastOnProgress + 10000 > new Date().getTime()) {
      //   await new Promise(resolve => setTimeout(resolve, lastOnProgress + 10000 - new Date().getTime()));
      // }

      console.log('Loaded! It has', pdf.numPages, 'page(s)');
      console.log('getMetadata:', await pdf.getMetadata());

      const occ = await pdf.getOptionalContentConfig();

      // Hide INFO layers
      console.log('Content groups:', occ.getGroups());
      const groupsToHide = [ 'transparant wit', 'Diecut' ];
      Object.keys(occ.getGroups())
          .filter(groupKey => groupsToHide.includes( groupKey ))
          .forEach(groupKey => occ.setVisibility(groupKey, false));

      const page = await pdf.getPage(1);
      const defaultViewport = page.getViewport({ scale: 1 });
      console.log('Default Viewport params:', defaultViewport);

      // Re-scale to 4k
      const scale = 4096 / Math.max(defaultViewport.width, defaultViewport.height);
      const viewport = page.getViewport({ scale });
      canvas.width = Math.floor(viewport.width);
      canvas.height = Math.floor(viewport.height);
      const canvasContext = canvas.getContext("2d", { willReadFrequently: true });

      const renderTask = page.render({
        canvasContext,
        viewport,
        // intent: 'print',
        optionalContentConfigPromise: Promise.resolve( occ )
      });

      renderTask.onContinue = fn => {
        console.log("renderTask.onContinue");
        fn();
      }

      await renderTask.promise;
      console.log("Renedering complete!");

      onLoad?.( canvas );

      // await new Promise(resolve => setTimeout(resolve, 1000));
      //
      // await page.render({
      //   canvasContext,
      //   viewport,
      //   intent: 'print',
      //   optionalContentConfigPromise: Promise.resolve( occ )
      // }).promise;
      //
      // onLoad( canvas );

    });
  }

}
