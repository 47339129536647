import * as React from "react";
import { useEffect, useState } from "react";
import { Mode, Scene } from "../scene/Scene";
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from "react-router-dom";
import { firebaseApp } from "../../firebase-config";
import { getDownloadURL, getStorage, ref as storageRef } from 'firebase/storage';
import { get, getDatabase, ref } from "firebase/database";
import * as qs from 'query-string';
import { PdfVisualizer } from "../pdf-visualizer/PdfVisualizer";
import { useSubdomain } from "./RouterUtils";


export const AppRouter = () =>
    <Router>
      <Routes>
        <Route path="/view/:file?" element={ <ViewScene/> }/> {/* TODO: unused */}
        <Route path="/review/:projectId" element={ <ReviewScene/> }/>
        <Route path="/render/:projectId" element={ <RenderScene/> }/> {/* TODO: this is temporary!! */ }
        <Route path="/share/:projectId" element={ <RenderScene/> }/> {/* TODO: this is temporary!! */ }
        <Route path="/p/:projectId" element={ <RenderScene/> }/> {/* TODO: this is temporary!! */ }
        <Route path="/qr/f1" element={ <F1Scene/> }/> {/* TODO: this is temporary!! */ }
        <Route path="/qr/f1-vouwauto" element={ <F1VouwAutoScene/> }/> {/* TODO: this is temporary!! */ }
        <Route path="/qr/sws" element={ <SwsScene/> }/> {/* TODO: this is temporary!! */ }
        <Route path="/qr/:code?" element={ <DefaultScene/> }/> {/* TODO: implement qr code handling */ }
        <Route path="/bx22003-25" element={ <QrScene projectId="-N7lpHgZxEcUUcFvpHEd"/> } /> {/* TODO: PoC for bx22003-25 */ }
        <Route path="/wonderfood-snack-doos" element={ <QrScene projectId="-N7lov-9e6OvKPf4pfMk"/> } /> {/* TODO: was a PoC for bx22003-25 */ }
        <Route path="/bx22003-60" element={ <QrScene projectId="-N7ln8EMf6hlfxCe1bqN"/> } /> {/* TODO: PoC for bx22003-60 */ }
        <Route path="/wonderfood-verspakket-doos" element={ <QrScene projectId="-N88Lsclp_owJEEVTvc9"/> }/> {/* TODO: was a PoC for bx22018-35 */ }
        <Route path="/fc150" element={ <QrScene projectId="-MkhKunUJtXi8XCmG5yf"/> } />
        <Route path="/fc150j" element={ <QrScene projectId="-MkhKunUJtXi8XCmG5yf"/> } />
        <Route path="/fc150y" element={ <QrScene projectId="-MmWiLwAJajUWdV6KkXP"/> } />
        <Route path="/casagamidemo" element={ <CasagamiScene/> }/> {/* TODO: this is temporary!! */ }
        <Route path="/moo" element={ <QrScene projectId="-Msjb-J_JM3LfOfV4kvO"/> } />
        <Route path="/quiks" element={ <QrScene projectId="-Mw8GGL0j0xAqxrWdfJw" embed={ true }/> } />
        <Route path="/SThree" element={ <QrScene projectId="-N3Vs23iem_8mgYeXS4G"/> } />
        <Route path="/SThreeLocal" element={ <SThreeScene/> } />
        <Route path="/vouwtruck-sales" element={ <QrScene projectId="-NF8_zU1CYfEzV85TBjE"/> } />
        <Route path="/Shiperone" element={ <QrScene projectId="-NTStThwKfqZZ31y5I2J"/> } />
        <Route path="/plancan-nl" element={ <PlanCanSceneNL/> } />
        <Route path="/plancan-de" element={ <PlanCanSceneDE/> } />
        <Route path="/vouwnext" element={ <VouwNext/> } />
        <Route path="/caddy" element={ <Caddy/> } />
        <Route path="/mdp" element={ <QrScene projectId="-NfCopgj9PgcmQ8mMWW-"/> } />
        <Route path="/ww-demo" element={ <WickedWood/> }/>
        <Route path="/cartoseat-demo" element={ <CartoSeat/> }/>
        <Route path="/doqmind-box" element={ <DoqMindBox/> }/>
        <Route path="/doqmind-bar" element={ <DoqMindBar/> }/>
        <Route path="/doqmind-demo" element={ <PdfVisualizer layoutId="dmkc"/> }/>
        <Route path="/rups-demo" element={ <RupsDemo/> }/>
        <Route path="/rups-pdf" element={ <PdfVisualizer layoutId="rups"/> }/>
        <Route path="/" element={ <VouwNext/> }/> {/* root path */ }
        <Route path="*" element={ <DefaultScene/> }/> {/* unmatched path */ }
      </Routes>
    </Router>

const DEFAULT_LAYOUT = "m01";
const DEFAULT_TEXTURE = "Ambulance.png"; // "MercedesSprinter_lines.png"; //
const DEFAULT_TEXTURE_URL = `/templates/${ DEFAULT_LAYOUT }/${ DEFAULT_TEXTURE }`;

const DefaultScene = () => {
  const location = useLocation();
  // TODO: trial of handling of subdomain
  const subdomain = useSubdomain(0);
  if (subdomain === 'rups') {
    return <RupsDemo/>
  }

  const queryParams = qs.parse(location.search);
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ 'embed' in queryParams }
          transparent={ 'trans' in queryParams }
          color={ queryParams['color'] as string }
          layoutId={ DEFAULT_LAYOUT }
          textureOrUrl={ DEFAULT_TEXTURE_URL }
      />
  );
};

const VouwNext = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ 'embed' in queryParams }
          transparent={ 'trans' in queryParams }
          color={ queryParams['color'] as string }
          layoutId="vouwnext"
          version={ 2 }
          textureOrUrl="/templates/vouwnext/Ambulance.png"
          backTextureUrl="/templates/vouwnext/backside-paper-emboss.jpg"
      />
  );
};

const Caddy = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ 'embed' in queryParams }
          transparent={ 'trans' in queryParams }
          color={ queryParams['color'] as string }
          layoutId="caddy"
          version={ 2 }
          textureOrUrl="/templates/caddy/Movida-MDP_VouwCaddy.jpg"
          backTextureUrl="/templates/caddy/backside-paper-emboss.jpg"
      />
  );
};

const CartoSeat = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          suppressConsent={ true }
          folded={ false }
          layoutId="cts"
          version={ 2 }
          textureOrUrl="/templates/cts/cartoseat-wonderfold.png"
          backTextureUrl="/templates/cts/cardboard.jpg"
      />
  );
};

const RupsDemo = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  return (
      <Scene
          mode={ Mode.DEFAULT }
          suppressConsent={ true }
          folded={ 'folded' in queryParams }
          layoutId="rups"
          version={ 2 }
          textureOrUrl="/templates/rups/dd.png"
          sbsTextureMode="h"
      />
  );
};

const WickedWood = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          folded={ true }
          layoutId="ww"
          version={ 2 }
          textureOrUrl="/templates/ww/ams_vs_utr.png"
          backTextureUrl="/templates/ww/half_wood.jpg"
      />
  );
};

const DoqMindBox = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  return (
      <Scene
          mode={ Mode.DEFAULT }
          suppressConsent={ true }
          folded={ 'folded' in queryParams }
          layoutId="dm"
          version={ 2 }
          color={ '#eee' }
          textureOrUrl="/templates/dm/DoqMind-front.png"
          backTextureUrl="/templates/dm/backside-paper-emboss.jpg"
      />
  );
};

const DoqMindBar = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  return (
      <Scene
          mode={ Mode.DEFAULT }
          suppressConsent={ true }
          folded={ 'folded' in queryParams }
          layoutId="dmkc"
          version={ 2 }
          color={ '#eee' }
          textureOrUrl="/templates/dmkc/koffie-crunch.png"
      />
  );
};

// TODO: this is temporary!!
const SwsScene = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ false }
          layoutId={ "m03" }
          textureOrUrl={ "/templates/m03/MAN-BestelBus_Schone-Wasstraat-2x4.png" }
      />
  );
};

// TODO: this is temporary!!
const SThreeScene = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ false }
          layoutId={ "tr1" }
          textureOrUrl={ "/templates/tr1/Sthree-20220602-4k.png" }
      />
  );
};

const PlanCanSceneNL = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ false }
          folded={ false }
          layoutId="plancan"
          version={ 2 }
          textureOrUrl="/templates/plancan/CanPlan_NL_FRONT_2K.png"
          backTextureUrl="/templates/plancan/CanPlan_NL_BACK_2K.png"
      />
  );
}

const PlanCanSceneDE = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ false }
          folded={ false }
          layoutId="plancan"
          version={ 2 }
          textureOrUrl="/templates/plancan/CanPlan_DE_FRONT_2K.png"
          backTextureUrl="/templates/plancan/CanPlan_DE_BACK_2K.png"
      />
  );
}

// TODO: this is temporary!!
const F1Scene = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ true }
          layoutId={ "f1" }
          textureOrUrl={ "/templates/f1/VouwAuto-Promo-Extended_F1-Auto_KLEURPLAAT.png" }
      />
  );
};

// TODO: this is temporary!!
const F1VouwAutoScene = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ true }
          layoutId={ "f1" }
          textureOrUrl={ "/templates/f1/VouwAuto-Promo-Extended_F1-Auto_VOUWAUTO.png" }
      />
  );
};

// TODO: this is temporary!!
const CasagamiScene = () => {
  return (
      <Scene
          mode={ Mode.DEFAULT }
          embedded={ true }
          layoutId={ "cg01" }
          textureOrUrl={ "/templates/cg01/woonschakel22017-scaled.jpg" }
      />
  );
};

// TODO: unused, remove
const ViewScene = () => {
  const { file } = useParams();
  const [ path, setPath ] = useState(null);
  const [ contents, setContents ] = useState(null);

  useEffect(() => {
    const newPath = "_master/" + file;
    if (path === newPath) return;

    const storage = getStorage(firebaseApp);
    if (file) {
      getDownloadURL(storageRef(storage, newPath)).then(url => {
        setContents(
            <Scene
                mode={ Mode.VIEW }
                layoutId={ DEFAULT_LAYOUT }
                textureOrUrl={ url }
            />
        );
        setPath(newPath);
      }).catch(err => {
        setContents(<>
          <code>Error loading <b>{ file }</b></code>
        </>);
        setPath(newPath);
      });
    }
  }, [ file, path ]);

  return contents;
};

const ReviewScene = () => {
  const { projectId } = useParams();
  const [ contents, setContents ] = useState(null);

  useEffect(() => {
    const database = getDatabase( firebaseApp );

    const projectIdRef = ref(database, `projects/${ projectId }`);
    get(projectIdRef).then(snapshot => {
      console.log('snapshot value', snapshot.val());
      const textureUrl = snapshot.val()['imageUrl'];
      if (!textureUrl) return;
      const layoutId = snapshot.val()['layoutId'] || DEFAULT_LAYOUT;
      const version = snapshot.val()['version'] || (['caddy','ww','rups'].includes(layoutId) || layoutId.startsWith('bx') ? 2 : 1);
      const mirrorLayout = snapshot.val()['mirrorLayout'] === true;
      const title = snapshot.val()['title'] || DEFAULT_LAYOUT;
      const backgroundColor = snapshot.val()['backgroundColor'];
      setContents(
          <Scene
              mode={ Mode.REVIEW }
              layoutId={ layoutId }
              version={ version }
              mirrorLayout={ mirrorLayout }
              color={ backgroundColor }
              textureOrUrl={ textureUrl }
              projectTitle={ title }
          />
      );
    });
  }, [ projectId ]);

  return contents;
};

// TODO: this is temporary!!
const RenderScene = () => {
  const { projectId } = useParams();
  const [ contents, setContents ] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const database = getDatabase( firebaseApp );

    const projectIdRef = ref(database, `projects/${ projectId }`);
    get(projectIdRef).then(snapshot => {
      console.log('snapshot value', snapshot.val());
      const queryParams = qs.parse(location.search);
      const textureUrl = snapshot.val()['imageUrl'];
      const backTextureUrl = snapshot.val()['backImageUrl'];
      const sbsTextureMode = snapshot.val()['sbsTextureMode'];
      if (!textureUrl) return;
      const layoutId = snapshot.val()['layoutId'] || DEFAULT_LAYOUT;
      const version = snapshot.val()['version'] || (['caddy','ww','rups'].includes(layoutId) || layoutId.startsWith('bx') ? 2 : 1);
      const mirrorLayout = snapshot.val()['mirrorLayout'] === true;
      const title = snapshot.val()['title'] || DEFAULT_LAYOUT;
      const backgroundColor = snapshot.val()['backgroundColor'] || queryParams['color'];
      const folded = queryParams['folded'] !== undefined;
      setContents(
          <Scene
              mode={ Mode.VIEW }
              layoutId={ layoutId }
              mirrorLayout={ mirrorLayout }
              embedded={ 'embed' in queryParams }
              transparent={ 'trans' in queryParams }
              color={ backgroundColor }
              textureOrUrl={ textureUrl }
              backTextureUrl={ backTextureUrl }
              sbsTextureMode={ sbsTextureMode }
              projectTitle={ title }
              version={ version }
              folded={ folded }
          />
      );
    });
  }, [ projectId, location.search ]);

  return contents;
};


type QrSceneProps = {
  projectId: string
  review?: boolean
  color? : string
  embed? : boolean
  trans? : boolean
}

const QrScene = ({ projectId, review, color, embed, trans } : QrSceneProps) => {
  const [ contents, setContents ] = useState(null);

  useEffect(() => {
    const database = getDatabase( firebaseApp );

    const projectIdRef = ref(database, `projects/${ projectId }`);
    get(projectIdRef).then(snapshot => {
      const textureUrl = snapshot.val()['imageUrl'];
      if (!textureUrl) return;
      const layoutId = snapshot.val()['layoutId'] || DEFAULT_LAYOUT;
      const mirrorLayout = snapshot.val()['mirrorLayout'] === true;
      const title = snapshot.val()['title'] || DEFAULT_LAYOUT;
      const backgroundColor = snapshot.val()['backgroundColor'] || color;
      const version = snapshot.val()['version'];
      setContents(
          <Scene
              mode={ review ? Mode.REVIEW : Mode.VIEW }
              layoutId={ layoutId }
              mirrorLayout={ mirrorLayout }
              embedded={ !!embed }
              transparent={ !!trans }
              color={ backgroundColor }
              textureOrUrl={ textureUrl }
              projectTitle={ title }
              version={ version }
          />
      );
    });
  }, [ projectId, review, color, embed, trans ]);

  return contents;
};
